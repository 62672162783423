<template>
    <div class="year_picker_wrpr datepicker-el" v-click-outside="close">
        <div class="datetimepicker-dummy-wrapper">
            <input 
                type="text" class="input" :value="localDate"
                @input="($event) => { $emit('input', $event.target.value); localDate = $event.target.value; }"
                @focus="focus" style="padding: 7.5px 44px 7.5px 7.5px;"
                @change="update(null)"
            >
        </div>
        <ul class="categories__dropdown dropdown-content flex-col" :class="{ 'active':openDropdownYear }">
            <li v-for="year in years" :key="year" :class="{'active': String(value) === String(year)}">
                <span @click="update(year)">{{ year }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ['value', 'min', 'max', ],
    data(){
        return {
            openDropdownYear: false,
            localDate: ''
        }
    },
    computed: {
        years(){
            let yearsArray = [];
            for (let index = 0; index <= +(this.max ? this.max : new Date().getFullYear()); index++) {
                if(index >= (this.min ? this.min : 1900)){
                    yearsArray.push(index);
                }
            }
            return yearsArray.reverse().filter(item => String(item).includes(String(this.localDate)));
        }
    },
    methods: {
        focus(){
            this.openDropdownYear = true;
            this.localDate = '';
        },
        close(){
            this.openDropdownYear = false;
        },
        update(value){
            if(value) {
                this.localDate = value;
                this.$emit('input', value);
            }

            setTimeout(() => {
               this.$emit('updateKeyNumber'); 
            }, 100);
        }
    },
    created(){
        if(!String(this.value).length){
            this.$emit('input', new Date().getFullYear());
        } else {
            this.localDate = String(this.value);
        }
    }
}
</script>

<style>
.year_picker_wrpr  {
    position: relative;
    max-width: 100%;
    min-width: initial;
    padding: 0;
    margin: 0;
}
.year_picker_wrpr ul li.active span {
    background: #F6F8F9;
}
.year_picker_wrpr ul li span {
    display: block;
    padding: 8px 14px;
    cursor: pointer;
    border-radius: 7px;
    text-transform: capitalize;
}
.year_picker_wrpr .categories__dropdown li:hover span {
    background: #F6F8F9;
}
</style>