<template>
	<!-- Card -->
	<div class="card" :class="{'processing':loading}">
		<div class="card__header">
			<div class="container flex">
				<div class="left flex">
					<div class="network-el el flex" v-if="dataFromxFarm.length">
						<svg width="24" height="24" viewBox="0 0 28 24" fill="none">
							<rect x="23" y="2" width="4" height="17" rx="1" :fill="5 <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl ? '#00283A' : '#B4C4CA'"></rect>
							<rect x="17" y="5" width="4" height="14" rx="1" :fill="4 <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl ? '#00283A' : '#B4C4CA'"></rect>
							<rect x="12" y="8" width="4" height="11" rx="1" :fill="3 <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl ? '#00283A' : '#B4C4CA'"></rect>
							<rect x="7" y="11" width="4" height="8" rx="1" :fill="2 <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl ? '#00283A' : '#B4C4CA'"></rect>
							<rect x="2" y="14" width="4" height="5" rx="1" :fill="1 <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl ? '#00283A' : '#B4C4CA'"></rect>
						</svg>
					</div>
					<div class="power-el el flex" v-if="dataFromxFarm.length">
						<!-- <span>{{ ((dataFromxFarm[dataFromxFarm.length-1].bv / dataFromxFarm[dataFromxFarm.length-1].bvLvl) * 100).toFixed() }}%</span> -->
						<!-- <span>{{ ((dataFromxFarm[dataFromxFarm.length-1].bvLvl / 5) * 100).toFixed() }}%</span> -->
						<!-- (+dataFromxFarm[dataFromxFarm.length-1].bvLvl || 5) -->
						<span>{{ dataFromxFarm.length ? (((+dataFromxFarm[dataFromxFarm.length-1].bv || 5) / 5) * 100).toFixed() : '100' }}%</span>
						<svg width="25" height="24" viewBox="0 0 25 24" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 18H3.5C2.11875 18 1 17.105 1 16V8C1 6.895 2.11875 6 3.5 6H18.5C19.8813 6 21 6.895 21 8V16C21 17.105 19.8813 18 18.5 18Z" stroke="#B4C4CA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<path d="M21 9H22.014C22.169 9 22.322 9.036 22.461 9.106L22.947 9.349C23.286 9.518 23.5 9.865 23.5 10.243V13.757C23.5 14.136 23.286 14.482 22.947 14.651L22.461 14.894C22.322 14.964 22.169 15 22.014 15H21" stroke="#B4C4CA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							<!-- <rect x="3" y="8" :width="(16 / 100) * ((dataFromxFarm[dataFromxFarm.length-1].bv / dataFromxFarm[dataFromxFarm.length-1].bvLvl) * 100).toFixed()" height="8" rx="1" fill="#00283A"></rect> -->
							<!-- <rect x="3" y="8" :width="(16 / 100) * ((dataFromxFarm[dataFromxFarm.length-1].bvLvl / 5) * 100).toFixed()" height="8" rx="1" fill="#00283A"></rect> -->
							<!-- (+dataFromxFarm[dataFromxFarm.length-1].bvLvl || 5) -->
							<rect x="3" y="8" :width="(16 / 100) * (((+dataFromxFarm[dataFromxFarm.length-1].bv || 5) / 5) * 100).toFixed()" height="8" rx="1" fill="#00283A"/>
						</svg>
					</div>
				</div>
				<div class="right flex">
					<label class="toggle-check">
						<input type="checkbox" name="toggle-check" v-model="isActive">
						<span class="toggle-check__body-wrapper">
							<span class="toggle-check__body">
								<span class="toggle-check__hendler"></span>
							</span>
						</span>
					</label>
				</div>
				<router-link class="label-el flex" :to="device.device_from_iot_data ? `/devices-dynamo/${device.id}` : `/devices/${device.id}`">
					<DeviceIcon :category="device.category" :isDynamoDbDevice="!!device.device_iot_data_id" />
					<div class="label-text">
						<span class="uppercase grey-text">{{ device.category }}</span>
						<h4>{{ device.name }}</h4>
					</div>
				</router-link>
			</div>
		</div>

		<div class="card__body flex-1">
			<div class="container">
				<div class="card__info" v-if="device.location">
					<h4 class="uppercase grey-text">{{ $translate('location') }}</h4>
					<span>
						<img src="@/assets/img/location-icon.svg" style="margin-right:14px;">
						{{ device.location ? device.location.address : 'Italy, Rome' }}
					</span>
				</div>
				<div class="card__info">
					<div class="flex justify-between align-center">
						<h4 class="uppercase grey-text">{{ $translate('device-info') }}</h4>
						<span v-if="lastValueData">
							<vue-moments-ago prefix="" suffix="ago" :date="lastValueData.timestamp" lang="en" />
						</span>
					</div>

					<span v-if="device.device_from_iot_data|| device.category === 'carbon-dioxide' || device.category === 'phosphine'" style="justify-content: space-between;" class="items-start flex-col">
						<span 
							style="color: var(--text-primary);" class="mt-5"
							v-for="data in parseLastValueData"
							:key="data.title"
						>
							{{data.title}}: {{ data.value }}
							<DeviceIcon v-if="data.deviceIcon" :category="data.deviceIcon" class="device_icon_card_value" />
						</span>
					</span>


					<span v-else style="justify-content: space-between;" class="items-start flex-col">
						<!-- <div class="flex flex-col"> -->
							<span style="flex: 1;justify-content: flex-start;color: var(--text-primary);">
								{{ lastValueData ? lastValueData.value : 0 }}{{ categories[device.category] ? categories[device.category].unit : '' }}
								<!-- <img 
									src="@/assets/img/tempa-icon.svg" style="margin-left:8px;"
									v-if="device.category === 'temperature' || device.category === 'weather-station-xsense' || device.category === 'temperature-humidity'"
								> -->
								<DeviceIcon :category="device.category" class="device_icon_card_value" />
							</span>
							<span 
								v-if="(device.category === 'temperature-humidity' || device.category === 'weather-station-xsense') && lastValueData && lastValueData.fullData && lastValueData.fullData.ah"
								style="color: var(--text-primary);" class="mt-5"
							>
								{{ $translate('air-humidity') }}: {{ lastValueData.fullData.ah }}%
							</span>
							<span 
								v-if="(device.category === 'temperature-humidity' || device.category === 'weather-station-xsense') && lastValueData && lastValueData.fullData && lastValueData.fullData.atd"
								style="color: var(--text-primary);" class="mt-5"
							>
								{{ $translate('dew-point-temp') }}: {{ lastValueData.fullData.atd }}°C
							</span>
							<span 
								v-if="device.category === 'weather-station-xsense' && lastValueData && lastValueData.fullData && lastValueData.fullData.aws"
								style="color: var(--text-primary);" class="mt-5"
							>
								{{ $translate('average-wind-speed') }}: {{ lastValueData.fullData.aws }}{{ $translate('km/h') }}
							</span>
							<span 
								v-if="device.category === 'weather-station-xsense' && lastValueData && lastValueData.fullData && lastValueData.fullData.mws"
								style="color: var(--text-primary);" class="mt-5"
							>
								{{ $translate('max-wind-speed') }}: {{ lastValueData.fullData.mws }}{{ $translate('km/h') }}
							</span>
							<span 
								v-if="device.category === 'weather-station-xsense' && lastValueData && lastValueData.fullData && lastValueData.fullData.sr"
								style="color: var(--text-primary);" class="mt-5"
							>
								{{ $translate('solar-radiation') }}: {{ lastValueData.fullData.sr }}{{ $translate('w/m2') }}
							</span>
						<!-- </div> -->
					</span>



				</div>
				<div class="card__info">
					<h4 class="uppercase grey-text">{{ $translate('alert') }}</h4>
					<span v-if="alerts.length" style="text-transform: capitalize;">
						{{ alerts[0].description.split('_').join(' ') }}
					</span>
					<span v-else>
						n/a
					</span>
				</div>
			</div>
		</div>

		<div class="card__footer">
			<div class="container">
				<div class="group-btn" v-if="!isDynamoDbDevice">
					<router-link :to="device.device_from_iot_data ? `/devices-dynamo/${device.id}?tab=info` : `/devices/${device.id}?tab=info`" class="btn btn-action btn-sm btn-center w-full">
						<inline-svg :src="require('@/assets/img/ico-settings.svg')"/>
						<span>{{ $translate('settings') }}</span>
					</router-link>
					<!-- <button type="button" class="btn btn-action btn-sm btn-center">
						<inline-svg :src="require('@/assets/img/ico-setting.svg')"/>
					</button> -->
				</div>
				<div class="group-btn" v-if="!isDynamoDbDevice">
					<!-- <router-link to="/reports/iot-sensors" class="btn btn-action btn-sm btn-center w-full"> -->
					<router-link :to="`/devices/${device.id}?tab=report`" class="btn btn-action btn-sm btn-center w-full">
						<inline-svg :src="require('@/assets/img/report-card-icon.svg')"/>
						<span>{{ $translate('report') }}</span>
					</router-link>
					<!-- <button type="button" class="btn btn-action btn-sm btn-center">
						<inline-svg :src="require('@/assets/img/calendar-card-icon.svg')"/>
					</button> -->
				</div>
				<div class="group-btn">
					<router-link :to="device.device_from_iot_data ? `/devices-dynamo/${device.id}` : `/devices/${device.id}?tab=overview`" class="btn btn-action btn-sm btn-center w-full">
						<inline-svg :src="require('@/assets/img/info-card-icon.svg')"/>
						<span>{{ $translate('device-info') }}</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
	<!-- Card -->	
</template>

<script>
import DeviceIcon from '@/components/base/DeviceIcon';
import { devStatus, devicesDataCategories, devCats } from '@/config';
import VueMomentsAgo from 'vue-moments-ago';
import { API, Storage, Auth } from 'aws-amplify';

export default {
	props: ['device',],
	components: {
		DeviceIcon,
		VueMomentsAgo,
	},
	data(){
		return {
			loading: true,
			lastValueData: null,
            categories: devicesDataCategories,
			dataFromxFarm: [],
			updateInterval: null,
			devCats: devCats,
		}
	},
	computed: {
		isDynamoDbDevice(){
			return !!this.device.device_iot_data_id
		},
		isActive: {
			get() {
				return devStatus[this.device.status] 
			},
			set(bool) {
				this.$emit('toggle', devStatus[bool])
			}
		},
		parseLastValueData(){
			if(this.device.device_iot_data_id && this.lastValueData){
				let values= Object.entries(this.lastValueData.device_data).filter(([key])=>(!['Timestamp','battery', 'device_eui'].includes(key))).map(([key,value])=>{
					let stringArray=key.split("_")
					stringArray=stringArray.map(str=>{
						if (!str) return str;
						return str[0].toUpperCase() + str.slice(1);
					})
					return {title:stringArray.join(" "),value:value}
				})
				return values
			}
			if((this.device.category === 'carbon-dioxide' || this.device.category === 'phosphine') && this.lastValueData){
				// console.log(this.devCats,'ssq');

				let values = [...this.devCats].find(c=>c.id===this.device.category).centaur_types_config.map(c=>({...this.categories[c],id: c, value:null}))
				// console.log(this.devCats,'ss',values);
				values = values.map(v=>{
					for(let i = this.lastValueData.values.length-1; i>0; i--){
						if(this.lastValueData.values[i].type===v.category){
							return {...v, value: this.lastValueData.values[i].value+v.unit, deviceIcon: v.id}
						}
					}
				})
				return values
			}
			return null
		},
		alerts(){
            return this.$store.state.alerts.filter(item => item.deviceID === this.device.id).sort(function(a,b){
				return new Date(a.datetime) - new Date(b.datetime);
			}) || []
        },
	},
	methods: {
		async getRealDeviceValue(device){
			if(device.config && device.config.device_id && this.categories[device.category]) {
				if(device.category === 'carbon-dioxide' || device.category === 'phosphine'){
					let realDeviceData = await this.$provider.centaurAPI.getRealDeviceData(device.config.device_id);

					if(realDeviceData && realDeviceData.length) {
						// let filteredByCategoryData = realDeviceData.filter(item => item.type === this.categories[device.category].category);
						// this.lastValueData = filteredByCategoryData[filteredByCategoryData.length-1];

						let sortRealDeviceData=[...realDeviceData]
						sortRealDeviceData.sort((a,b)=>(Date.parse(a.timestamp)-Date.parse(b.timestamp)))
						// console.log('sortRealDeviceData',sortRealDeviceData);
						this.lastValueData={...sortRealDeviceData[sortRealDeviceData.length-1], values: sortRealDeviceData}
					

					}
				} else {
					this.dataFromxFarm = await this.$provider.xFarm.getDeviceData(device.config.device_id, { 'from': new Date().toISOString(), 'periodTo': `90d` });

					let valueVariableName = null;
					
					if(device.category === 'temperature' || device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
						valueVariableName = 'at';
					}
					
					if(device.category === 'leaf-moisture'){
						valueVariableName = 'lw';
					}
					
					if(device.category === 'soil-moisture'){
						valueVariableName = 'sm1';
					}

					let realDeviceData = this.dataFromxFarm.map(item => {
						return {
							"timestamp": item.time,
							"type": this.categories[device.category].category,
							"value": item[valueVariableName],
							"deviceId": item.did,
							"fullData": item
						}
					});

					if(realDeviceData && realDeviceData.length) {
						this.lastValueData = realDeviceData[realDeviceData.length-1]
					}	
				}
			}
			if(device.device_iot_data_id){
				let path = `/items/${device.device_iot_data_id}`;
				let myInit = {
					headers: {
						"Content-Type" : "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
				}
				let iotData = await API.get('stripeapi', path, myInit);

				iotData = JSON.parse(JSON.stringify(iotData)).sort(function(x, y){
					return new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime();
				}).reverse()

				// console.log(`Single iotData ${device.device_iot_data_id}:`, iotData);
				// console.log('iotData[0]:', iotData[0]);
				this.lastValueData = iotData[0]
			}
		}
	},
	async created(){
		await this.getRealDeviceValue(this.device);
		this.loading = false;

		var index = 0;
		this.updateInterval = setInterval(async () => {
			let localIndex = index;
			// console.log('new interval: ', localIndex)
			index++
			await this.getRealDeviceValue(this.device);
			// console.log('Resolved promise: ', localIndex)
		}, 60 * 1000);
	},
	beforeDestroy(){
		// console.log('Destroyed')
		clearInterval(this.updateInterval);
	}
}
</script>