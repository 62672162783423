<template>
	<header class="header-dash">
		<div class="header-dash__body">
			<div class="header-dash__buttons buttons-group">
				<button type="button" class="btn btn-w-shadow btn-standart-2" @click="$emit('toggle')">
					<SvgIcons icon="message-question-checkmark.svg" />
					<span>{{ $translate('tutorial') }}</span>
				</button>
				<router-link to="/support" class="btn btn-w-shadow btn-standart-2">
					<SvgIcons icon="Headphones.svg" />
					<span>{{ $translate('support') }}</span>
				</router-link>
			</div>
			<div class="header-dash__user-info">
				<LangSelector />
				<div class="notifications dropdown-wrapper" v-click-outside="closeNotificationDrop">
					<button 
						type="button" 
						class="btn btn-sm btn-transp dropdown-btn" 
						:class="{ active: notificationsDropdown }"
						@click="toggleNotificationsDrop"
					>
						<SvgIcons icon="bell-notifications.svg" />
					</button>
					<div class="notifications__dropdown flex flex-col dropdown-content" :class="{ active: notificationsDropdown }">
						<div class="dropdown-head flex">
							<h5 class="flex-auto">{{ $translate('notifications') }}</h5>
							<button type="button" class="btn color-blue" @click="notificationsHideAll" v-if="notifications.length">
								{{ $translate('hide-all') }}
							</button>
						</div>
						<div class="dropdown-body flex-auto">
							<div class="notifications-item flex" v-for="(notification, notificationIndex) in notificationsData" :key="notificationIndex">
								<div class="label-icon" :class="[notification.color]">
									<img :src="require(`@/assets/img/notification-${notification.type}.svg`)">
								</div>
								<div class="notifications-item__text flex-auto">
									<h6>{{ notification.text }}</h6>
									<span>{{ notification.time }}</span>
								</div>
								<button type="button" class="btn delete-item">
									<img src="@/assets/img/x.svg">
								</button>
							</div>
							<div class="notifications-item flex justify-center" v-if="!notifications.length">
								<h3>{{ $translate('no-new-notifications') }}</h3>
							</div>

							<div class="notifications-item-more more-block"></div>
							<button 
								class="btn color-blue more-items"
								@click="notificationsMore = !notificationsMore"
								v-if="notifications.length > 3"
							>
								{{ $translate('show-more') }}
							</button>
						</div>
					</div>
				</div>

				<div class="dropdown-wrapper" v-click-outside="closeMe">
					<div class="header-dash__user user dropdown-btn" :class="{ active: userDropdown }" @click="toggleUserDrop">
						<div class="user__img">
							<img v-if="profile_photo" :src="profile_photo">
							<img v-else src="@/assets/img/user.png">
						</div>
						<span class="user__name">{{ userData.fullname }}</span>
						<img src="@/assets/img/Arrow-down.svg">
					</div>
					<div class="dropdown-content user-dropbown" :class="{ active: userDropdown }">
						<div class="dropdown-body">
							<div class="user-dropbown__info">
								<div class="user-dropbown__info-img">
									<img v-if="profile_photo" :src="profile_photo">
									<img v-else src="@/assets/img/user.png">
								</div>
								<h5 class="user-dropbown__name">{{ userData.fullname }}</h5>
								<span class="user-dropbown__email">{{ authData.attributes.email }}</span>
								<div class="user-dropbown__buttons">
									<router-link to="/change-account-company" class="btn btn-action btn-sm btn-center w-full">
										<inline-svg :src="require(`@/assets/img/ico-country-house.svg`)"/>
										<span>{{ $translate('company') }}</span>
									</router-link>

									<router-link to="/settings" class="btn btn-action btn-sm btn-center w-full">
										<inline-svg :src="require(`@/assets/img/ico-settings.svg`)"/>
										<span>{{ $translate('settings') }}</span>
									</router-link>

									<button type="button" class="btn btn-action btn-sm btn-center w-full" @click="signMeOut">
										<inline-svg :src="require(`@/assets/img/ico-exit-log-out-arrow.svg`)"/>
										<span>{{ $translate('sign-out') }}</span>
									</button>
								</div>
							</div>  
							<div class="user-dropbown__accounts">
								<div class="accounts-item">
									<div class="user__img">
										<img v-if="profile_photo" :src="profile_photo">
										<img v-else src="@/assets/img/user.png">
									</div>
									<div class="accounts-item__text">
										<h6 class="user-dropbown__name">
											{{ userData.fullname }}
										</h6>
										<span class="user-dropbown__email">{{ authData.attributes.email }}</span>
									</div>
								</div>
								<!-- <div class="accounts-item">
									<div class="user__img">
										<img src="@/assets/img/user-img.jpg">
									</div>
									<div class="accounts-item__text">
										<h6 class="user-dropbown__name">
											Jane Cooper
										</h6>
										<span class="user-dropbown__email">jane.cooper@example.com</span>
									</div>
								</div> -->
								<button type="button" class="add-acount">
									<div class="add-acount__img">
										<img src="@/assets/img/ico-single-user-add.svg">
									</div>
									<span>{{ $translate('add-another-account') }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Tutorial -->
		<slot />
	</header>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import SvgIcons from "@/components/base/SvgIcons";
import LangSelector from '@/components/base/LangSelector.vue';

export default {
	components: {
		SvgIcons,
		LangSelector
	},
	data() {
		return {
			userDropdown: false,
			notificationsDropdown: false,
			authUser: null,
			notifications: [
				// {
				// 	id: 1,
				// 	text: '“Water Leak Sensor” have problem',
				// 	type: '1',
				// 	color: 'blue',
				// 	time: '4 hour ago'
				// },
				// {
				// 	id: 2,
				// 	text: '“Thermometer #2” disabled',
				// 	type: '2',
				// 	color: 'purple',
				// 	time: '4 hour ago'
				// },
				// {
				// 	id: 3,
				// 	text: '“Electricity Module” disconected',
				// 	type: '3',
				// 	color: 'yellow',
				// 	time: '4 hour ago'
				// },
				// {
				// 	id: 4,
				// 	text: '“pH Level Sensor” disconected',
				// 	type: '4',
				// 	color: 'red',
				// 	time: '4 hour ago'
				// },
				// {
				// 	id: 5,
				// 	text: '“Water Leak Sensor” have problem',
				// 	type: '1',
				// 	color: 'blue',
				// 	time: '4 hour ago'
				// },
				// {
				// 	id: 6,
				// 	text: '“Thermometer #2” have problem',
				// 	type: '2',
				// 	color: 'purple',
				// 	time: '4 hour ago'
				// },
			],
			notificationsMore: false,
		}
	},
	computed: {
		userData() {
			return this.$store.state.userData || {}
		},
		companyData() {
			return this.$store.state.companyData || {}
		},
		authData() {
			return this.$store.state.authData || {}
		},
		profile_photo() {
			return this.$store.state.profile_photo
		},
		notificationsData(){
			return !this.notificationsMore ? [...this.notifications].slice(0, 3) : this.notifications;
		}
	},
	methods: {
		toggleUserDrop() {
			this.userDropdown = !this.userDropdown
		},
		closeMe() {
			this.userDropdown = false
		},
		toggleNotificationsDrop() {
			this.notificationsDropdown = !this.notificationsDropdown
		},
		closeNotificationDrop() {
			this.notificationsDropdown = false
		},
		signMeOut() {
			Auth.signOut();
		},
		notificationsHideAll(){
			this.closeNotificationDrop();
			this.notificationsMore = false;
			this.notifications = [];
		}
	},
}
</script>

