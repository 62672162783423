export const filterAuthEvents = (payload) => {
    if (!payload) return
    const {
        event,
        data,
    } = payload;
    if (!data) return
    const {
        message
    } = data

    //console.log(event);

    switch (event) {
        case 'forgotPasswordSubmit_failure':
            return {
                title: 'Password Reset failed',
                message
            }
        case 'signIn_failure':
            return {
                title: 'Sign In failed',
                message
            }
        case 'signUp_failure':
            return {
                title: 'Sign Up failed',
                message
            }
        case 'tokenRefresh_failure':
            return {
                title: 'Auth Refresh failed',
                message
            }
        case 'confirmPasswordFailure':
            return {
                title: 'Validation failed',
                message
            }
        case 'verifyEmail_failure':
        case 'verifyEmail_failed':
            return {
                title: 'Verification failed',
                message
            }
    }
    return
}
const isEqual = (a, b) => {
    if (a === b) return true;
    if ((a==null && b=='')||(b==null && a=='')) return true;
    if ((a=="false" && b==false)||(a==false && b=='false')) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a.prototype !== b.prototype) return false;
    let keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => isEqual(a[k], b[k]));
};
export const compareData = (update, backup, deep = false) => {
    const changes = {};
    for (const [key, value] of Object.entries(backup)) {
        if(typeof value==='object' && value!==null){
            const newValue = update[key];
            if (deep && deep.indexOf(key)>-1 ){
                console.log(value,newValue,!isEqual(newValue, value));
                if (newValue !== undefined && !isEqual(newValue, value)) changes[key] = update[key];
            }
        }else{
            const newValue = update[key];
            if (newValue !== undefined && newValue !== value) changes[key] = update[key];
        }
    }
    return changes
}

export const checkWarranty = (date, value) => {
    const warranty = Date.parse(date.split('.').reverse().join('-')) >= Date.now() ? 'valid' : 'error';
    return warranty === value
}

export const grandFilter = (arr, filters,searchField='name') => {
    const { search } = filters;
    return arr.filter(r => {
        let result = [];
        let _r = r;
        if(r.device_data) _r = r.device_data;
        if (search && search.length) {
            //console.log("hel",_r[searchField] , search,_r[searchField] === search);
            if( _r[searchField] && _r[searchField].length){
                result.push(
                    _r[searchField].toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
            }else{
                result.push(false)
            }
        }
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'search' || value === 'all') continue
            if (key === 'warranty_period') {
                result.push(checkWarranty(_r[key], value));
                continue;
            }
            if (key === 'from_date') {
                let _value = value.split('.').reverse().join('-')
                result.push(
                    (new Date(_r.Timestamp)).getTime() > (new Date(_value)).getTime()
                )
                continue;
            }
            if (key === 'to_date') {
                let _value = value.split('.').reverse().join('-')
                result.push(
                    (new Date(_r.Timestamp)).getTime() < (new Date(_value )).getTime()
                )
                console.log('to_date', _r.Timestamp, (new Date(_r.Timestamp)).getTime(), _value , (new Date(_value )).getTime())
                continue;
            }
            //console.log("hel",_r[key] , value,_r[key] === value);
            result.push(_r[key] === value);
        }
        return !result.includes(false)
    })
}

export default {
    filterAuthEvents,
    compareData,
    checkWarranty,
    grandFilter
}