<template>
	<div class="content__body">
		<div class="filter-options" :class="{ 'filter_drops_fixed':!rows.length }">
			<div class="filter-options-wrapper flex flex-xxl-wrap flex-auto" :style="filterWrapperStyle">
				<slot name="filters" />
				<!-- Резет скорее всего нужно вынести в родительский елемент так как он есть не везде и будет на 1 пропс меньше  -->
				<button type="button" class="btn btn-w-shadow btn-standart-2" @click="resetFilters" style="margin-left:10px" v-if="isResetFilterEvent" > 
					<inline-svg :src="require(`@/assets/img/ico-remove.svg`)" />
				</button>
				<!-- поле Sort By есть во многих таблицах лучше записать его сюда, оно работает через пропс SortFieldsForDropdown -->
				<div class="flex items-center" v-if="sortFieldsForDropdown">
					<span class="select-title">{{ $translate('sort-by') }}</span>
					<div class="categories dropdown-wrapper">
						<button type="button" class="btn btn-w-shadow btn-standart dropdown-btn" @click="toggleSortDropdown"  v-click-outside="closeSortDropdown">
							<span class="active-categories">{{getValueForDropdown()}}</span>
							<span><img src="@/assets/img/Arrow-down.svg"></span>
						</button>
						<div class="categories__dropdown dropdown-content w-full" style="max-width: none; min-width:auto;" :class="{active:sortDropdownActive}">
							<ul>
								<li v-for="(col,i) in sortFieldsForDropdown" :key="i" @click="sortTable(col.filter);closeSortDropdown()">
									<span :title="col.name">{{col.name}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="table-wrapper" v-if="rows.length">
			<table class="table" >
				<thead>
					<tr>
						<th v-for="(col, i) in columns" :key="i" :colspan="col.colspan" :class="col.class">
							<div class="table-filter" :class="{ flex: i === 0 && !col.disableCheckbox }">
								<label class="module__check" v-if="i === 0 && !col.disableCheckbox">
									<input type="checkbox" :checked="isCheckedAll" @change="toggleAllCheckedInputs"/>
									<span class="check"></span>
								</label>
								<div class="table-filter__options" :class="{ 'flex-auto': i===0 }" v-if="col.filter">
									<button class="table-filter__btn" @click="sortTable(col.filter)" :class="isSort(col.filter)">
										<span>{{ col.name }}</span>
									</button>
								</div>
								<span v-else>{{col.name}}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="r in rows" :key="r.id" :class="{checked: checkedInputs[r.id]}" >
						<slot name="row" :r="r" />
					</tr>
				</tbody>
			</table>
			
		</div>
		<div v-else-if="noDataMessage">
			<h2 style="font-size: 22px;line-height: initial;">{{ noDataMessage }}</h2>
		</div>
	</div>	
</template>

<script>
export default {
	name: 'TableAdvanced',
	data(){
		return {
			sortDropdownActive:false
		}
	},
	props: {
		columns: {
			type: Array,
			default: () => []
		},
		rows: {
			type: Array,
			default: () => []
		},
		value: {
			default: null,
		},
		
		sortFieldsForDropdown:{
			type: Array,
			default: () => null
		},

		noDataMessage:{
			type: String,
			// default: () => "No data found"
		},
		isCheckedAll:{
			type: Boolean,
			default:()=>false
		},
		checkedInputs:{
			type: Object,
			default:()=>({})
		},
		filterWrapperStyle: {
			type: String,
			default:()=>""
		}
	},
	computed: {
		isSort() {
			return filter => filter === this.value ? 'active' : ''
		},
		isResetFilterEvent(){
            return !!this.$listeners.resetFilters
        }
	},
	methods: {
		sortTable(filter) {
			console.log('sortTable(filter):', filter);
			let result = filter === this.value ? null : filter;
			this.$emit('input', result);
		},
		resetFilters(){
			this.$emit('resetFilters')
		},
		toggleSortDropdown(){
			this.sortDropdownActive=!this.sortDropdownActive
		},
		closeSortDropdown(){
			this.sortDropdownActive=false
		},
		getValueForDropdown(){
			let name=this.sortFieldsForDropdown.length ? this.sortFieldsForDropdown[0].name : 'Sort';
			if(this.value){
				this.sortFieldsForDropdown.forEach(element => {
					if(element.filter===this.value){
						name=element.name
					}
				});
			}

			if(!this.value){
				this.sortTable(this.sortFieldsForDropdown[0].filter)
			}
			return name
		},
		toggleAllCheckedInputs(){
			// console.log("table");
			this.$emit('toggleAllCheckedInputs')
		}
	
	}
}
</script>