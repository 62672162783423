<template>
<div v-if="rows==0">
    <div class="no-data"  v-if="isFiltered">
		<p>{{searchTitle}}</p>
	</div>
    <div class="no-data" v-else >
		<p>{{title}}</p>
        <router-link class="btn btn-w-shadow btn-standart-2" v-if="to" :to="to">{{addTitle}}</router-link>

	</div>
</div>
</template>



<script>

export default {
    props:["filters","rows","title","searchTitle","addTitle","to"],
    computed:{
        isFiltered(){
            let result=false
            Object.entries(this.filters).forEach(filter=>{
                result=result|| (filter[1]&&filter[1]!=='all')
            })
            return result
        }
    }
}
</script>
<style scoped>
    .btn{
        margin-top: 20px;
    }
    .no-data{
        margin-top: 50px;           
    }
</style>