<template>
	<div class="datepicker-el">
		<p v-if="placeholder && (!value || value === '' || value === 'all')" style="position: absolute; top: 50%; left: 12px; z-index: 2; transform: translateY(-50%); pointer-events: none;">{{ placeholder }}</p>
		<input type="date" class="current-data" ref="trigger">
		<a class="datepicker_clear_btn" v-if="canClear && value && value.length" @click.self.stop="clear">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
				<path d="M15 5L5 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M5 5L15 15" stroke="#9AA6AC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</a>
	</div>
</template>

<script>
import bulmaCalendar from 'bulma-calendar';
export default {
	name: 'DatePicker',
	props: ['value', 'dateFormat', 'canClear', 'displayMode', 'placeholder'],
	calendar: null,

	watch: {
		value(val) {
			if(val){
				this.calendar.value(val);
			} else{
				this.calendar.clear()
			}
		}
	},

	methods: {
		clear(){
			this.calendar.clear();
			this.$emit('input', this.calendar.value());
			setTimeout(() => {
				this.calendar.hide();
			}, 0);
		}
	},

	mounted() {
		let checkPickersPosition = function(){
			let pickers = document.querySelectorAll('.datetimepicker');
			pickers.forEach(element => {
				let pickerClientRect = element.getBoundingClientRect();

				if(pickerClientRect.top + pickerClientRect.height + 68 > document.querySelector('body').getBoundingClientRect().height){
					element.classList.add('top_position');
				} else {
					element.classList.remove('top_position');
				}
			});
		};

		this.calendar = new bulmaCalendar(this.$refs.trigger, {
			showHeader: false,
        	showFooter: false,
			dateFormat: this.dateFormat || 'dd.MM.yyyy',
			startDate: this.value,
			displayMode: this.displayMode ? this.displayMode : 'default',
		});

		this.calendar.on('save', () => {
			this.$emit('input', this.calendar.value());
			setTimeout(() => {
				this.calendar.hide();
			}, 0)
		});

		this.calendar.on('hide', () => {
			this.$emit('change');
			checkPickersPosition();
		});

		this.calendar.on('show', () => {
			checkPickersPosition();
		});
	},

	beforeDesctroy() {
		this.calendar = null;
	}
}
</script>