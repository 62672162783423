<template>
    <div class="dropdown-wrapper" v-click-outside="close">
            <button 
                type="button" 
                class="btn btn-sm btn-transp dropdown-btn" 
                :class="{ active: isOpen }"
                @click="toggleDropdown"
            >
                <img :src="activeLang.img" class="lang__img" alt="">
            </button>
            <div class="lang__dropdown flex flex-col dropdown-content" :class="{ active: isOpen }">
                <!-- <div class="dropdown-head flex">
                    <h5 class="flex-auto">{{ $translate('notifications') }}</h5>
                </div> -->
                <div class="dropdown-body flex-auto">
                    <ul>
                        <li class="lang__item" v-for="item in languages" :key="item.lang" :class="{ 'active': item.lang == activeLang.lang}" @click="selectLang(item)">
                            <img :src="item.img" alt="" class="lang__img">
                            <span>{{ item.title }}</span>
                        </li>
                    </ul>
                    <!-- <div class="notifications-item flex" v-for="(notification, notificationIndex) in notificationsData" :key="notificationIndex">
                        <div class="label-icon" :class="[notification.color]">
                            <img :src="require(`@/assets/img/notification-${notification.type}.svg`)">
                        </div>
                        <div class="notifications-item__text flex-auto">
                            <h6>{{ notification.text }}</h6>
                            <span>{{ notification.time }}</span>
                        </div>
                        <button type="button" class="btn delete-item">
                            <img src="@/assets/img/x.svg">
                        </button>
                    </div>
                    <div class="notifications-item flex justify-center" v-if="!notifications.length">
                        <h3>{{ $translate('no-new-notifications') }}</h3>
                    </div>

                    <div class="notifications-item-more more-block"></div> -->
                </div>
            </div>
        </div>
</template>
<script>
export default {
    data: () => ({
        isOpen: false,
        languages: [
            {
                img: '/img/En.png',
                lang: 'en',
                title: 'English'
            },
            {
                img: '/img/Fr.png',
                lang: 'fr',
                title: 'Français'
            },
            {
                img: '/img/Pg.png',
                lang: 'pg',
                title: 'Português'
            },
            {
                img: '/img/Gm.png',
                lang: 'gm',
                title: 'Deutsch'
            },
            {
                img: '/img/It.png',
                lang: 'it',
                title: 'Italiano'
            },
            {
                img: '/img/Sp.png',
                lang: 'sp',
                title: 'Español'
            },
        ]
    }),
    computed: {
        activeLang() {
            return this.languages.find(el => el.lang == this.$store.getters.lang);
        }
    },
    methods: {
        close() {
            this.isOpen = false;
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectLang(item) {
            localStorage.setItem('lang', item.lang);
            this.$store.commit('SET_LANG', item.lang);
        },
    },
    created() {
        let lang = localStorage.getItem('lang') || 'en',
            langItem = this.languages.find(el => el.lang == lang);

        this.selectLang(langItem)
    }
}
</script>
<style scoped>
.lang__dropdown {
    min-width: 300px;
    left: auto;
    right: 10px;
    position: fixed;
    top: 60px;
}
.lang__item {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.lang__item:hover {
    background-color: var(--table-row-hover);
}

.lang__item.active {
    background-color: var(--table-row-hover);
}

.lang__item.active:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 42%;
    width: 15px;
    height: 8px;
    border-left: 1px solid #b9baba;
    border-bottom: 1px solid #b9baba;
    transform: rotate(-45deg) translateY(-50%);
}

.lang__img {
    max-width: 32px;
    min-width: 32px;
}

.lang__img + span {
    margin-left: 12px;
}
</style>