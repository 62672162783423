export default {
    "iot-management":	"IOT MANAGEMENT",
    "iot-dashboard":	"IOT Dashboard",
    "devices":	"Devices",
    "device-groups":	"Device Groups",
    "rules":	"Rules",
    "products":	"PRODUCTS",
    "products":	"Products",
    "categories":	"Categories",
    "dispatch":	"DISPATCH",
    "dispatch":	"Dispatch",
    "incoming-dispatch":	"Incoming Dispatch",
    "transaction-history":	"Transaction History",
    "qr-scanner":	"QR Scanner",
    "farm-management":	"FARM MANAGEMENT",
    "farm-map":	"Farm Map",
    "fields-/-paddock-list":	"Fields / Paddock List",
    "livestock-list":	"Livestock List",
    "actions-list":	"Actions List",
    "reports":	"Reports",
    "settings":	"SETTINGS",
    "teams":	"Teams",
    "settings":	"Settings",
    "tutorial":	"Tutorial",
    "support":	"Support",
    "company":	"Company",
    "settings":	"Settings",
    "sign-out":	"Sign Out",
    "rows-per-page":	"Rows per page",
    "prev":	"Prev",
    "next":	"Next",
    "add-iot-device":	"Add IOT Device",
    "all-categories":	"All Categories",
    "device-info":	"Device Info",
    "alert":	"Alert",
    "settings":	"Settings",
    "report":	"Report",
    "device-info":	"Device Info",
    "search-settings":	"Search settings",
    "location":	"Location",
    "groups":	"Groups",
    "status":	"Status",
    "date-from":	"Date from",
    "date-to":	"Date to",
    "reset-all-filters":	"Reset all filters",
    "apply":	"Apply",
    "devices":	"Devices",
    "search":	"Search",
    "categories":	"Categories",
    "locations":	"Locations",
    "status":	"Status",
    "type":	"Type",
    "map":	"Map",
    "device-name":	"Device Name",
    "category":	"Category",
    "date-of-purchase":	"Date of Purchase",
    "type":	"Type",
    "location":	"Location",
    "device-info":	"Device Info",
    "rows-per-page":	"Rows per page",
    "prev":	"Prev",
    "next":	"Next",
    "add-iot-device":	"Add IOT Device",
    "device-name":	"Device Name",
    "device-type":	"Device Type",
    "device-category":	"Device Category",
    "description":	"Description",
    "date-of-manufacture":	"Date of Manufacture",
    "date-of-purchase":	"Date of Purchase",
    "device-id":	"Device id",
    "device-groups":	"Device Groups",
    "add-group":	"Add Group",
    "name":	"Name",
    "iot-devices":	"IOT Devices",
    "actions":	"Actions",
    "rename-group":	"Rename Group",
    "edit-group":	"Edit group",
    "report":	"Report",
    "add-a-group":	"Add a Group",
    "set-up-a-rule-for-each-of-the-onboarded-iot-devices.":	"Set up a rule for each of the onboarded IOT devices.",
    "group-name":	"Group Name",
    "select-devices":	"Select Devices",
    "selected-devices":	"Selected Devices",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.":	"To set up rules you need to select some of onboarded IOT devices.",
    "previous":	"Previous",
    "cancel":	"Cancel",
    "submit":	"Submit",
    "rules":	"Rules",
    "add-a-rule":	"Add a Rule",
    "rule":	"Rule",
    "iot-devices":	"IOT Devices",
    "actions":	"Actions",
    "enabled":	"Enabled",
    "disabled":	"Disabled",
    "add-a-rule":	"Add a Rule",
    "alert-me-when-all-the-selected-conditions-are-satisfied:":	"Alert me when all the selected conditions are satisfied:",
    "basic-info":	"Basic Info",
    "basic-setting-for-a-rule":	"Basic setting for a rule",
    "name":	"Name",
    "selected-devices":	"Selected Devices",
    "to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.":	"To set up rules you need to select some of onboarded IOT devices.",
    "behaviour":	"Behaviour",
    "specify-how-your-devices-should-behave-on-triger.":	"Specify how your devices should behave on triger.",
    "define-the-conditions-to-trigger-an-alert": "Define the conditions to trigger an alert",
    "add-behaviour":	"Add Behaviour",
    "observation-period":	"Observation period",
    "parameter":	"Parameter",
    "behaviour":	"Behaviour",
    "operator":	"Operator",
    "value":	"Value",
    "alert-actions":	"Alert Actions",
    "select-what-action-your-rule-should-take":	"Select what action your rule should take",
    "email":	"Email",
    "display-name":	"Display Name",
    "temperature-warning":	"Temperature warning",
    "note":	"Note",
    "you-should-check-the-device!":	"You should check the device!",
    "cancel":	"Cancel",
    "submit":	"Submit",
    "dashboard":	"Dashboard",
    "machine-to-machine":	"Machine to Machine",
    "select-device":	"Select Device",
    "select-option":	"Select Option",
    "action":	"Action",
    "previous":	"Previous",
    "create-a-rule":	"Create A Rule",
    "products":	"Products",
    "add-a-product":	"Add a Product",
    "search-by-name,-id,-rfid,-serial#...":	"Search by name, ID, RFID, serial#...",
    "product-name":	"Product Name",
    "category":	"Category",
    "parent-product":	"Parent Product",
    "serial":	"Serial",
    "location":	"Location",
    "device":	"Device",
    "status":	"Status",
    "manufacturing-date":	"Manufacturing Date",
    "expiry-date":	"Expiry Date",
    "add-a-product":	"Add a Product",
    "fill-in-information-to-add-new-product":	"Fill in information to add new product",
    "basic-info":	"Basic Info",
    "category":	"Category",
    "select-option":	"Select Option",
    "product-name":	"Product Name",
    "untitled-product":	"Untitled Product",
    "quantity":	"Quantity",
    "units":	"Units",
    "description":	"Description",
    "write-product-description-here...":	"Write product description here...",
    "media":	"Media",
    "drag-&-drop-or-browse-to-choose-a-file":	"Drag & Drop or browse to choose a file",
    "manufacture-info":	"Manufacture Info",
    "serial-number":	"Serial Number",
    "manufacturing-date":	"Manufacturing Date",
    "expiry-date":	"Expiry Date",
    "invoice-number":	"Invoice Number",
    "purchase-order":	"Purchase Order",
    "rfid-number":	"RFID Number",
    "batch-id":	"Batch ID",
    "location":	"Location",
    "assign-device":	"Assign Device",
    "here-you-can-find-devices-assigned-to-your-product":	"Here you can find devices assigned to your product",
    "here-you-can-add-a-device-monitoring-your-product": "Here you can add a device monitoring your product",
    "here-you-can-add-a-parent-product-to-this-product": "Here you can add a parent product to this product",
    "parent-products":	"Parent Products",
    "here-you-can-find-parent-products-assigned-to-your-product":	"Here you can find parent products assigned to your product",
    "type-of-event":	"Type of Event",
    "farm":	"Farm",
    "link-to-a-farm":	"Link to a Farm",
    "here-you-can-link-farm-items-to-a-product":	"Here you can link farm items to a product",
    "please-link-farm-items-to-a-product":	"Please link farm items to a product",
    "add-herd":	"Add Herd",
    "add-building":	"Add Building",
    "add-field":	"Add Field",
    "add-paddock":	"Add Paddock",
    "name":	"Name",
    "type":	"Type",
    "total-area":	"Total Area",
    "land-use":	"Land Use",
    "crop-type":	"Crop Type",
    "colour-on-map":	"Colour on Map",
    "qr-code-settings":	"QR Code Settings",
    "company-data":	"Company Data",
    "select-info-that-you-want-to-show-in-qr-code":	"Select info that you want to show in QR Code",
    "location":	"Location",
    "documentation":	"Documentation",
    "production-area":	"Production area",
    "productivity":	"Productivity",
    "history-of-company":	"History of company",
    "year-of-foundation":	"Year of foundation",
    "soils":	"Soils",
    "certifications":	"Certifications",
    "media":	"Media",
    "production-per-year":	"Production per year",
    "cellar-work":	"Cellar work",
    "philosophy":	"Philosophy",
    "assign-devices":	"Assign Devices",
    "here-you-can-find-devices-assigned-to-your-product":	"Here you can find devices assigned to your product",
    "product-chain-data":	"Product Chain Data",
    "select-info-that-you-want-to-show-in-qr-code":	"Select info that you want to show in QR Code",
    "datetime-creation":	"Datetime creation",
    "datetime-receive":	"Datetime receive",
    "parent-products":	"Parent products",
    "smart-conditions":	"Smart conditions",
    "rules-business-conditions":	"Rules business conditions",
    "datetime-return":	"Datetime return",
    "list-of-dispatches":	"List of dispatches",
    "datetime-dispatch":	"Datetime dispatch",
    "basic-info":	"Basic info",
    "linked-iot-devices":	"Linked IOT devices",
    "actions-info":	"Actions info",
    "create-a-product":	"Create A Product",
    "categories":	"Categories",
    "sort-by":	"Sort by",
    "category":	"Category",
    "number-of-products":	"Number of products",
    "last-update-date":	"Last update date",
    "date-of-creation":	"Date of creation",
    "add-products":	"Add Products",
    "add-a-category":	"Add a Category",
    "here-you-can-create-new-category-and-add-products":	"Here you can create new category and add products",
    "basic-info":	"Basic Info",
    "category-name":	"Category Name",
    "selected-products":	"Selected Products",
    "to-create-a-category-you-need-to-select-some-products.":	"To create a category you need to select some products.",
    "add-new-product":	"Add New Product",
    "dispatch":	"Dispatch",
    "create-a-dispatch":	"Create a Dispatch",
    "date-of-creation":	"Date of creation",
    "dispatch-id":	"Dispatch ID",
    "status":	"Status",
    "owner":	"Owner",
    "no.-batch":	"No. Batch",
    "no.-products":	"No. Products",
    "smart-condition-status":	"Smart condition status",
    "business-condition-status":	"Business condition status",
    "view-the-dispatch":	"View the Dispatch",
    "delete-the-dispatch":	"Delete the Dispatch",
    "create-a-dispatch":	"Create a Dispatch",
    "here-you-can-create-dispatch-for-your-products":	"Here you can create dispatch for your products",
    "here-you-can-track-all-your-shipments-of-products-to-other-users": "Here you can track all your shipments of products to other users",
    "basic-info":	"Basic Info",
    "dispatch-id":	"Dispatch ID",
    "dispatch-documents":	"Dispatch Documents",
    "list-of-documents-attached-to-despatch":	"List of documents attached to despatch",
    "add-document":	"Add Document",
    "select-companies":	"Select Companies",
    "add-various-companies-to-your-dispatch":	"Add various companies to your dispatch",
    "add-other-users-to-your-dispatch": "Add other users to your dispatch",
    "add-companies":	"Add Companies",
    "products":	"Products",
    "dispatch-products":	"Dispatch Products",
    "list-of-batches-and-products-attached-to-dispatch":	"List of batches and products attached to dispatch",
    "csv-example":	"CSV Example",
    "upload-csv":	"Upload CSV",
    "add-batch":	"Add Batch",
    "contract-conditions":	"Contract Conditions",
    "add-conditions":	"Add Conditions",
    "add-conditions-to-your-contract-dispatch":	"Add conditions to your contract dispatch",
    "add-smart-condition":	"Add Smart Condition",
    "select-batch-id":	"Select Batch ID",
    "select-device":	"Select Device",
    "parameter-observed":	"Parameter observed",
    "operator":	"Operator",
    "value":	"Value",
    "enter-number...":	"Enter number...",
    "from-date":	"From Date",
    "from-time":	"From Time",
    "to-date":	"To Date",
    "to-time":	"To Time",
    "add-business-condition":	"Add Business Condition",
    "type-1":	"Type 1",
    "event-to-be-monitored":	"Event to be monitored",
    "description-of-certifications-required...":	"Description of certifications required...",
    "event-to-be-monitored":	"Event to be monitored",
    "incoming-dispatch":	"Incoming Dispatch",
    "no-incoming-dispatches-yet!":	"No Incoming Dispatches yet!",
    "transaction-history":	"Transaction History",
    "product-name":	"Product Name",
    "quantity/units":	"Quantity/units",
    "batch-id":	"Batch id",
    "name-of-the-dispatch":	"Name of the dispatch",
    "date":	"Date",
    "type":	"Type",
    "conditions":	"Conditions",
    "status-of-the-product":	"Status of the product",
    "sort-by":	"Sort by",
    "qr-scanner":	"QR Scanner",
    "qr-code-scanner":	"QR Code Scanner",
    "here-you-can-scan-your-enismaro-qr-code":	"Here you can scan your Enismaro QR Code",
    "start-cam-scanner":	"Scan From Camera",
    "stop-cam-scanner":	"Stop Scan From Camera",
    "scan-from--file":	"Scan From File",
    "farm-map":	"Farm Map",
    "filter":	"Filter",
    "tool-/-equipment":	"Tool / Equipment",
    "herd-zones":	"Herd Zones",
    "building-zones":	"Building Zones",
    "infrastructure-zones":	"Infrastructure Zones",
    "field-zones":	"Field Zones",
    "paddock-zones":	"Paddock Zones",
    "add-building-/-infrastructure":	"Add Building / Infrastructure",
    "add-paddock-":	"Add Paddock ",
    "add-animal-":	"Add Animal ",
    "add-tool-/-equipment":	"Add Tool / Equipment",
    "basic-info":	"Basic Info",
    "type":	"Type",
    "select-option":	"Select Option",
    "name":	"Name",
    "description":	"Description",
    "write-description-here...":	"Write description here...",
    "position":	"Position",
    "brand":	"Brand",
    "model":	"Model",
    "working-width-(meters)":	"Working width (meters)",
    "power-(kw-or-hp)":	"Power (kw or hp)",
    "production-year":	"Production year",
    "plate-number":	"Plate number",
    "frame-number":	"Frame number",
    "date-of-purchase":	"Date of purchase",
    "revision-date":	"Revision date",
    "date-of-technical-check":	"Date of technical check",
    "registration-document":	"Registration document",
    "attach-file":	"Attach File",
    "insurance-document":	"Insurance document",
    "user-manual":	"User manual",
    "photo":	"Photo",
    "drag-&-drop-or-browse-to-choose-a-file":	"Drag & Drop or browse to choose a file",
    "add-point":	"Add Point",
    "add-field":	"Add Field",
    "draw-and-describe-your-field-on-map":	"Draw and describe your field on map",
    "borders-definition":	"Borders Definition",
    "pivot-area":	"Pivot Area",
    "map":	"Map",
    "basic-info":	"Basic Info",
    "field-name":	"Field Name",
    "total-area":	"Total Area",
    "colour-on-map":	"Colour on Map",
    "crop":	"Crop",
    "variety":	"Variety",
    "crop-year":	"Crop year",
    "description":	"Description",
    "land-register":	"Land Register",
    "add-cadastral-parcel":	"Add cadastral parcel",
    "country":	"Country",
    "city":	"City",
    "sheet-land-registry":	"Sheet land registry",
    "particle-land-registry":	"Particle land registry",
    "area-land-registry":	"Area land registry",
    "actions":	"Actions",
    "soil":	"Soil",
    "soil-map":	"Soil map",
    "granulometry":	"Granulometry",
    "%-sand":	"% Sand",
    "%-silt":	"% Silt",
    "%-clay":	"% Clay",
    "ph":	"pH",
    "carbonates-%":	"Carbonates %",
    "organic-substance-%":	"Organic substance %",
    "organic-carbon-%":	"Organic carbon %",
    "mineral-nitrogen":	"Mineral nitrogen",
    "assign-devices":	"Assign Devices",
    "here-you-can-find-devices-assigned-to-your-field":	"Here you can find devices assigned to your field",
    "add-building-/-infrastructure":	"Add Building / Infrastructure",
    "draw-and-describe-your-building-/-infrastructure-on-map":	"Draw and describe your building / infrastructure on map",
    "basic-info":	"Basic Info",
    "name-of-the-building-/-infrastructure":	"Name of the Building / Infrastructure",
    "type-of-building-/-infrastructure":	"Type of Building / Infrastructure",
    "colour-on-map":	"Colour on Map",
    "description":	"Description",
    "add-paddock":	"Add Paddock",
    "draw-and-describe-your-paddock-on-map":	"Draw and describe your paddock on map",
    "basic-info":	"Basic Info",
    "paddock-name":	"Paddock Name",
    "total-area":	"Total Area",
    "land-use":	"Land Use",
    "colour-on-map":	"Colour on Map",
    "crop":	"Crop",
    "variety":	"Variety",
    "crop-year":	"Crop year",
    "add-animal":	"Add Animal",
    "draw-and-describe-your-animal-on-map":	"Draw and describe your animal on map",
    "basic-info":	"Basic Info",
    "name-of-the-animal":	"Name of the animal",
    "type-of-animal":	"Type of animal",
    "breed":	"Breed",
    "status":	"Status",
    "sex":	"Sex",
    "tag-number":	"Tag number",
    "date-of-birth":	"Date of Birth",
    "birth-weight":	"Birth weight",
    "raised-or-purchased":	"Raised or purchased",
    "disposal":	"Disposal",
    "assign-paddock-/-building-/-field":	"Assign Paddock / Building / Field",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your-herd":	"Here you can find paddocks / buildings / fields assigned to your herd",
    "fields-/-paddock-list":	"Fields / Paddock List",
    "date-creation-(from)":	"Date creation (from)",
    "date-creation-(to)":	"Date creation (to)",
    "livestock-list":	"Livestock List",
    "actions-list":	"Actions List",
    "reports":	"Reports",
    "herd-movement-records":	"Herd movement records",
    "feed-records":	"Feed records",
    "edx-analysis":	"EDX analysis",
    "treatments/action-details":	"Treatments/action details",
    "this-report-is-empty":	"This report is empty",
    "create-edx-report":	"Create EDX Report",
    "edx-analysis-create":	"EDX Analysis Create",
    "add--entry":	"Add  Entry",
    "name":	"Name",
    "vet1-map-sum-spectrum":	"VET1 Map Sum Spectrum",
    "line-type":	"Line Type",
    "apparent-concentration":	"Apparent Concentration",
    "k-ratio":	"k Ratio",
    "wt%":	"Wt%",
    "wt%-sigma":	"Wt% Sigma",
    "standard-label":	"Standard Label",
    "actions":	"Actions",
    "report-name":	"Report Name",
    "image":	"Image",
    "comments":	"Comments",
    "add-comment":	"Add Comment",
    "comment-decsription":	"Comment decsription",
    "no-data":	"No Data",
    "field-logbook":	"Field logbook",
    "date":	"Date",
    "name-of-the-operation":	"Name of the operation",
    "name-of-the-product":	"Name of the product",
    "weight-":	"Weight ",
    "size":	"Size",
    "cultivation-type":	"Cultivation type",
    "adversity":	"Adversity",
    "field-details":	"Field details",
    "paddock-details":	"Paddock details",
    "building-details":	"Building details",
    "iot-sensors":	"IOT sensors",
    "custom-reports":	"Custom reports",
    "create-custom-report":	"Create Custom Report",
    "add-column":	"Add Column",
    "add-entry":	"Add Entry",
    "report-name":	"Report Name",
    "actions":	"Actions",
    "teams":	"Teams",
    "add-user":	"Add User",
    "role":	"Role",
    "grants":	"Grants",
    "status":	"Status",
    "add-a-user":	"Add a User",
    "basic-info":	"Basic Info",
    "add-a-temporal-validity":	"Add a temporal validity",
    "grants":	"Grants",
    "all":	"All",
    "iot-management":	"IOT management",
    "products":	"Products",
    "dispatch":	"Dispatch",
    "farm-management":	"Farm management",
    "location":	"Location",
    "assign-device":	"Assign Device",
    "settings":	"Settings",
    "account-settings":	"Account Settings",
    "profile-photo":	"Profile Photo",
    "upload":	"Upload",
    "remove":	"Remove",
    "full-name":	"Full Name",
    "password":	"Password",
    "change-password":	"Change Password",
    "old-password":	"Old password",
    "new-password":	"New password",
    "repeat-new-password":	"Repeat new password",
    "confirm":	"Confirm",
    "delete-account":	"Delete Account",
    "by-deleting-your-account-you-will-lose-all-your-data":	"By deleting your account you will lose all your data",
    "do-you-confirm-delete-for-your-account?":	"Do you confirm delete for your account?",
    "company-info":	"Company Info",
    "company-photo":	"Company Photo",
    "company-name":	"Company Name",
    "year-of-foundation":	"Year of Foundation",
    "website":	"Website",
    "history-of-the-company":	"History of the Company",
    "product-&-farm-info":	"Product & Farm Info",
    "production-/-year":	"Production / year",
    "production-area":	"Production area",
    "soils":	"Soils",
    "cellar-work":	"Cellar work",
    "productivity":	"Productivity",
    "philosophy":	"Philosophy",
    "vat-number":	"VAT number",
    "fiscal-code":	"Fiscal code",
    "location":	"Location",
    "country":	"Country",
    "city":	"City",
    "province":	"Province",
    "certifications":	"Certifications",
    "download":	"Download",
    "documentation":	"Documentation",
    "media":	"Media",
    "user-management":	"User Management",
    "plan-&-billing":	"Plan & Billing",
    "payment-method":	"Payment Method",
    "expires":	"Expires",
    "your-plan":	"Your Plan",
    "see-plan-details":	"See plan details",
    "upgrade-plan":	"Upgrade plan",
    "cancel-plan":	"Cancel plan",
    "iot-dashboard":	"IOT Dashboard",
    "devices":	"Devices",
    "device-groups":	"Device Groups",
    "rules":	"Rules",
    "products":	"Products",
    "categories":	"Categories",
    "teams":	"Teams",
    "settings":	"Settings",
    "billing-cycles":	"Billing Cycles",
    "next-payment":	"Next payment",
    "switch-billing-cycle":	"Switch billing cycle",
    "new-to-enismaro?":	"New to Enismaro?",
    "create-an-account":	"Create an Account",
    "login":	"Login",
    "password":	"Password",
    "forgot-your-password?":	"Forgot your password?",
    "all-rights-reserved":	"All rights reserved",
    "terms":	"Terms",
    "privacy":	"Privacy",
    "security":	"Security",
    "support":	"Support",
    "create-an-account":	"Create an Account",
    "confirm-password":	"Confirm Password",
    "sign-up":	"Sign Up",
    "already-have-an-account?":	"Already have an account?",
    // New
    "add-action":	"Add Action",
    "edit":	"Edit",
    "delete":	"Delete",
    "actions-not-found-reset-filters-or-add-a-new-action":	"Actions not found. Reset filters or add a new action.",
    "reset-your-password":	"Reset your password",
    "code":	"Code",
    "submit-code":	"Submit Code",
    "send-code":	"Send Code",
    "verify-your-account":	"Verify your account",
    "enter-the-verification-code":	"Enter the verification code",
    "add-devices":	"Add Devices",
    "add-device":	"Add Device",
    "assign-device-to-group":	"Assign Device(s) to group",
    "add-iot-device":	"Add IOT Device(s)",
    "delete-device?":	"Delete Device?",
    "delete-device":	"Delete Device",
    "do-you-confirm-deleting-this-device-from-group?":	"Do you confirm deleting this device from group?",
    "none":	"none",
    "delete-group?":	"Delete Group?",
    "add-a-new-group":	"Add a new group",
    "no-products-corresponding-to-the-criteria":	"No Products Corresponding To The Criteria",
    "view-document":	"View Document",
    "batch":	"Batch",
    "delete-batch":	"Delete Batch",
    "po-number":	"PO Number",
    "quantity&qnit":	"Quantity&Unit",
    "no-added-products":	"No Added Products",
    "business-condition":	"Business Condition",
    "receive-the-dispatch":	"Receive the Dispatch",
    "return-the-dispatch":	"Return the Dispatch",
    "no-dispatches-yet!":	"No Dispatches yet!",
    "no-dispatches-corresponding-to-the-criteria":	"No Dispatches Corresponding To The Criteria",
    "cancel-dispatch":	"Cancel Dispatch",
    "check-all-dispatch-information-before-cancel-a-dispatch":	"Check all dispatch information before cancel a dispatch",
    "companies":	"Companies",
    "documents":	"Documents",
    "modify-a-dispatch":	"Modify a Dispatch",
    "here-you-can-modify-a-dispatch":	"Here you can modify a dispatch",
    "qr-code":	"QR Code",
    "canceled":	"Canceled",
    "pending-for-delivery":	"Pending for Delivery",
    "ingoing":	"Ingoing",
    "cancel-a-dispatch":	"Cancel a Dispatch",
    "send-dispatch":	"Send Dispatch",
    "return-dispatch":	"Return Dispatch",
    "receive-dispatch":	"Receive Dispatch",
    "check-all-dispatch-information-before-confirm-receiving-dispatch":	"Check all dispatch information before confirm receiving dispatch",
    "check-all-dispatch-information-before-return-a-dispatch":	"Check all dispatch information before return a dispatch",
    "view-dispatch":	"View Dispatch",
    "delivery-date":	"Delivery Date",
    "approve":	"Approve",
    "reject":	"Reject",
    "deliver-a-dispatch":	"Deliver a Dispatch",
    "add":	"Add",
    "set-up-action-and-attahch-fields-or-animals":	"Set up action and attahch fields or animals",
    "select-on-of-two-categories":	"Select on of two categories",
    "type-of-activity":	"Type of activity",
    "related-fields-/-herd":	"Related Fields / Herd",
    "attach-fields-or-herd-to-your-action":	"Attach fields or herd to your action",
    "add-fields":	"Add Fields",
    "add-heard":	"Add Herd",
    "save":	"Save",
    "set-up-building-/-infrastructure-on-map-and-write-down-basic-info":	"Set up building / infrastructure on map and write down basic info",
    "here-you-can-find-devices-assigned-to-your-paddock":	"Here you can find devices assigned to your paddock",
    "set-up-field-on-map-and-write-down-basic-info":	"Set up field on map and write down basic info",
    "grazeable-or-arable-area":	"Grazeable or Arable Area",
    "set-up-paddock-on-map-and-write-down-basic-info":	"Set up paddock on map and write down basic info",
    "assign-paddock-/-building":	"Assign Paddock / Building",
    "name-of-herd":	"Name of Herd",
    "type-of-herd":	"Type of Herd",
    "age-class":	"Age Class",
    "origin":	"Origin",
    "number-of-animals-in-the-herd":	"Number of animals in the herd",
    "change-paddock-/-building":	"Change Paddock / Building",
    "filters":	"Filters",
    "draw-and-describe-your":	"Draw and describe your",
    "on-map":	"on map",
    "address-not-found":	"Address not found",
    "custom-crop":	"Custom crop",
    "here-you-can-find-devices-assigned-to-your":	"Here you can find devices assigned to your",
    "zone":	"zone",
    "here-you-can-find-paddocks-/-buildings-/-fields-assigned-to-your":	"Here you can find paddocks / buildings / fields assigned to your",
    "paddock-/-building":	"Paddock / Building",
    "change":	"Change",
    "select":	"Select",
    "incoming":	"Incoming",
    "add-product":	"Add Product",
    "view-farm-item":	"View farm item",
    "edit-farm-item":	"Edit farm item",
    "at-the-day-of-product-creation":	"at the day of product creation",
    "during-the-day/week/month-of-the-product-creation":	"during the day/week/month of the product creation",
    "during-the-smart-condition-in-the-dispatch":	"during the smart condition in the dispatch",
    "max/min-value-of-the":	"Max/min value of the",
    "during-a-time-range":	"during a time range",
    "during-a-time-range":	"Exact value of the",
    "assign-devices-to-product":	"Assign Device(s) to product",
    "product-added-successfully!":	"Product added successfully!",
    "do-you-confirm-delete-file?":	"Do you confirm delete file?",
    "add-product-to-category":	"Add product to category",
    "add-category":	"Add Category",
    "category-successfully-added!":	"Category successfully added!",
    "view-product":	"View Product",
    "delete-product":	"Delete Product",
    "delete-product?":	"Delete Product?",
    "do-you-confirm-deleting-this-product-from-category?":	"Do you confirm deleting this product from category?",
    "add-products-to-category":	"Add products to category",
    "here-you-can-find-devices-assigned-to-your-prouct":	"Here you can find devices assigned to your prouct",
    "do-you-confirm-delete-a-product":	"Do you confirm delete a product",
    "no-products-yet!":	"No products yet!",
    "product-category":	"Product Category",
    "product-id":	"Product ID",
    "date-/-time":	"Date / Time",
    "event":	"Event",
    "here-you-can-manage-farm-items-linked-to-your-product":	"Here you can manage farm items linked to your product",
    "farm-items-not-linked":	"Farm items not linked",
    "iot-date":	"IOT date",
    "back":	"Back",
    "related-fields":	"Related Fields",
    "back-to-log-book-list":	"Back to log book list",
    "bottle-production-/-year":	"Bottle production / year",
    "vineyard-area":	"Vineyard area",
    "view-report":	"View Report",
    "delete-report":	"Delete Report",
    "reports-not-found.-try-resetting-the-filters":	"Reports not found. Try resetting the filters",
    "delete-report?":	"Delete Report?",
    "do-you-confirm-delete-a-report?":	"Do you confirm delete a report?",
    "report-with-calendar":	"Report with calendar",
    "full-list-actions":	"Full List Actions",
    "not-found":	"not found",
    "create":	"Create",
    "product":	"Product",
    "images":	"Images",
    "no-images":	"No Images",
    "no-comments":	"No comments",
    "view-device":	"View Device",
    "set-rules":	"Set Rules",
    "schedule":	"Schedule",
    "devices-not-found":	"Devices not found",
    "related-herds":	"Related Herds",
    "no-devices-found":	"No devices found",
    "assign-a-specific-rule-to-the-iot-device":	"Assign a specific rule to the IOT device.",
    "info":	"Info",
    "rule-name":	"Rule name",
    "behavior":	"Behavior",
    "temporal-setting":	"Temporal Setting",
    "on":	"On",
    "off":	"Off",
    "day-of-observation":	"Day of observation",
    "time-of-observation-from":	"Time of observation from",
    "time-of-observation-to":	"Time of observation to",
    "sms-/-email":	"SMS / Email",
    "sms":	"SMS",
    "webhook":	"Webhook",
    "settings-succesfully-updated":	"Settings succesfully updated",
    "add-payment-method":	"Add payment method",
    "plan":	"Plan",
    "contact-us":	"Contact us",
    "support-tickets-list-is-empty":	"Support tickets list is empty",
    "raise-a-ticket":	"Raise a Ticket",
    "frequently-asked-questions":	"Frequently Asked Questions",
    "you-can-find-answers-for-your-questions-here":	"You can find answers for your questions here",
    "video-tutorials":	"Video tutorials",
    "in-development":	"In development",
    "scheduled-calls":	"Scheduled Calls",
    "here-you-can-find-calls-that-you-scheduled":	"Here you can find calls that you scheduled",
    "scheduled-a-call-list-is-empty":	"Scheduled a Call list is empty",
    "schedule-a-call":	"Schedule a Call",
    "a-user":	"a User",
    "fill-in-information-to-add-new-user-to-team":	"Fill in information to add new user to team",
    "period-start":	"Period Start",
    "period-end":	"Period End",
    "edit-info":	"Edit Info",
    "description-of-the-company":	"Description of the Company",
    "last-data-received":	"Last data received",
    "refresh":	"Refresh",
    "overview":	"Overview",
    "measurements":	"Measurements",
    "actual":	"Actual",
    "alerts":	"Alerts",
    "battery-status":	"Battery status",
    "signal-strength":	"Signal strength",
    "device-description":	"Device Description",
    "warranty-period":	"Warranty Period",
    "save-changes":	"Save Changes",
    "history":	"History",
    "date-and-time":	"Date and Time",
    "dynamo-db-device":	"Dynamo DB Device",
    "timestamp":	"Timestamp",
    "data-not-found.-Reset-filters-or-add-a-new-element.":	"Data not found. Reset filters or add a new element.",
    "delete-paddock?":	"Delete Paddock?",
    "delete-herd?":	"Delete Herd?",
    "do-you-confirm-delete-a-herd":	"Do you confirm delete a herd",
    "enismaro-food&wine-traceability":	"Enismaro - food&wine traceability",
    "producer":	"Producer",
    "place-of-origin":	"Place of origin",
    "device-data":	"Device data",
    "sold-by":	"Sold by",
    "during-a-time-range":	"during a time range",
    "manufacturer":	"Manufacturer",
    "result":	"Result",
    "see-your-result":	"See your result",
    "clear-cam-scanner-result":	"Clear Cam Scanner Result",
    "error":	"Error",
    "file":	"File",
    "new":	"New",
    "remove-uploaded-file":	"Remove Uploaded File",
    "select-all":	"Select All",
    "admin":	"Admin",
    "user":	"User",
    "moderator":	"Moderator",
    "sold":	"Sold",
    "terms-and-conditions":	"Terms and conditions",
    "privacy-policy":	"Privacy policy",
    "cookies-policy":	"Cookies policy",
    "notifications":	"Notifications",
    "hide-all":	"Hide All",
    "no-new-notifications":	"No new notifications",
    "show-more":	"Show More",
    "add-another-account":	"Add another account",
    "end-tutorial":	"End Tutorial",
    "Batch Name":	"Batch Name",
    "temporal-settings":	"Temporal settings",
    "time-of-observation":	"Time of observation",
    "view":	"View",
    "initial":	"initial",
    "type-name":	"Type name",
    "add-device-group":	"Add Device Group",
    "add-new-product-to-category":	"Add new Product to category",
    "set-units":	"Set Units",
    "here-you-can-find-parent-products-assigned-to-your-prouct":	"Here you can find parent products assigned to your prouct",
    "transformation":	"Transformation",
    "work-in-the-garden":	"Work in the garden",
    "stocking-density":	"Stocking density",
    "smart-condition":	"Smart Condition",
    "parameter-obeserved":	"Parameter obeserved",
    "certification":	"Certification",
    "age-vine":	"Age vine",
    "temperature-at-the-day-of-product-creation":	"Temperature at the day of product creation",
    "temperature-during-the-day/week/month-of-the-product-creation":	"Temperature during the day/week/month of the product creation",
    "temperature-during-the-smart-condition-in-the-dispatch":	"Temperature during the smart condition in the dispatch",
    "max/min-value-of-the-temperature-during-a-time-range":	"Max/min value of the temperature during a time range",
    "exact-value-of-the-temperature-during-a-time-range":	"Exact value of the temperature during a time range",
    "date-/-time-of-creation":	"Date / Time of creation",
    "smart-rules-/-business-conditions":	"Smart rules / business conditions",
    "date-/-time-of-dispatch-dispatch":	"Date / Time of dispatch dispatch",
    "date-/-time-of-dispatch-receive":	"Date / Time of dispatch receive",
    "date-/-time-of-dispatch-return":	"Date / Time of dispatch return",
    "transaction-history-of-the-product":	"Transaction history of the product",
    "device-rule-log":	"Device rule log",
    "basic-info-of-the-product":	"Basic info of the product",
    "parent-product(s)":	"Parent product(s)",
    "email-alert":	"Email Alert",
    "select-mode":	"Select Mode",
    "telephone":	"Telephone",
    "callback-url":	"Callback URL",
    "add-card":	"Add card",
    "card-number":	"Card Number",
    "cvv-number":	"CVV Number",
    "exp.-date":	"Exp. Date",
    "list-empty":	"List empty",
    "do-you-want-to-use-csv-example-for-this-dispatch?":	"Do you want to use CSV Example for this Dispatch?",
    "choose-format":	"Choose format",
    "field-logbook-report":	"Field Logbook Report",
    "in-the-process...":	"In the process...",
    "export":	"Export",
    "actions-details":	"Actions Details",
    "iot-device":	"IOT Device",
    "grazeable-area":	"Grazeable Area",
    "click-to-download-png-qr-code-file":	"Click to download PNG QR-code file",
    "this-is-the":	"This is the",
    "linked-to-this-dispatch.-attach-it-to-your-shipment-or-send-it-to-the-receiving-user":	"linked to this dispatch. Attach it to your shipment or send it to the receiving user",
    "ok":	"Ok",
    "scan":	"Scan",
    "for-more-information-about-product":	"for more information about product",
    "subject":	"Subject",
    "select-problem-category":	"Select problem category",
    "fill-in-all-the-fields":	"Fill in all the fields",
    "update-a-ticket":	"Update a Ticket",
    "do-you-want-reject-a-dispatch?-you-can-modify-a-dispatch-before-reject-the-dispatch.":	"Do you want reject a Dispatch? You can modify a dispatch before reject the Dispatch.",
    "rename-category":	"Rename category",
    "update-a-call":	"Update a Call",
    "select-date":	"Select date",
    "available-time":	"Available time",
    "select-date-and-time":	"Select date and time",
    "click-ok-to-proceed":	"Click OK to proceed",
    "dispatch-conditions":	"Dispatch Conditions",
    "list-of-conditions-attached-to-dispatch":	"List of conditions attached to dispatch",
    "view-more":	"View More",
    "assign-to-an-existing-rule":	"Assign to an existing rule",
    "assign-to-a-rule":	"Assign to a rule",
    "create-a-new-rule": "Create a new rule",
    "delete-rule-from-this-device?":	"Delete rule from this device?",
    "activity-schedule":	"Activity Schedule",
    "define-period-of-activation":	"Define period of activation",
    "mon":	"Mon",
    "tue":	"Tue",
    "wed":	"Wed",
    "thu":	"Thu",
    "fri":	"Fri",
    "sat":	"Sat",
    "sun":	"Sun",
    "sispatch-companies":	"Dispatch Companies",
    "list-of-companies-attached-to-despatch":	"List of companies attached to despatch",
    "list-of-documents-attached-to-dispatch":	"List of documents attached to dispatch",
    "close":	"Close",
    "air-humidity":	"Air humidity",
    "dew-point-temp":	"Dew point temp",
    "average-wind-speed":	"Average wind speed",
    "km/h":	"km/h",
    "max-wind-speed":	"Max wind speed",
    "solar-radiation":	"Solar Radiation",
    "w/m2":	"W/m2",
    "got-it":	"Got It",
    "date-&-time":	"Date & Time",
    "temperature":	"Temperature",
    "battery":	"Battery",
    "fields/paddocks-map":	"Fields/paddocks map",
    "fields/paddocks-list":	"Fields/paddocks list",
    "validate":	"Validate",
    "today":	"Today",
    "clear":	"Clear",
}