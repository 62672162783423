import { API } from 'aws-amplify';
import {
    createRules,
    // createDeviceRules,
    updateRules,
    deleteRules
} from '@/graphql/mutations'

import {
    listDevices,
    listRules
} from '@/graphql/queries'

export default class Graphql {
    constructor(store) {
        this.store = store
    }

    get companyID() {
        return this.store.state.companyData.id
    }

    async test() {
        const devices = await API.graphql({
            query: listDevices
        }).then(res => res.data.listDevices)
        console.log(devices);
        return devices
    }

    async getRules() {
        const rules = await API.graphql({
            query: listRules
        }).then(res => res.data.listRules)
        console.log(rules);
        return rules
    }

    async createRule(deviceID = '2254166b-39ee-486e-840d-bba11f48d70d') {
        // 1. Create rule
        const input = {
            companyID: this.companyID,
            name: 'Test rule',
            status: 'active', // active | inactive
            behavior: {
                temporal_settings: 'weekly',
                telemetry: 'temperature',
                aggregation: 'average',
                operator: 'greater_than',
                value: '30'
            },
            alert_actions: {
                sms_email: true,
                dashboard: true,
                m2m: true,
                webhook: true,
                display_name: 'Temperature Goes High',
                note: 'Lorem Ipsum',
                email: null,
                tel: null,
                deviceID,
                device_action: 'switch_off',
                callback_url: 'https://example.com/api'
            }

        }
        const rule = await API.graphql({
            query: createRules,
            variables: {
                input
            }
        }).then(res => res.data.createRules)

        // 2. Attach device(s) createDeviceRules
        // const deviceRule = await API.graphql({
        //     query: createDeviceRules,
        //     variables: {
        //         input: {
        //             deviceID,
        //             rulesID: rule.id
        //         }
        //     }
        // }).then(res => res.data.createDeviceRules)

        //console.log(rule, deviceRule);

        return deviceRule
    }

    async updateRule(deviceID = '2254166b-39ee-486e-840d-bba11f48d70d') {

    }
}