<template>
    <span class="sidebar__icon">
        <inline-svg :src="require(`@/assets/img/${this.path}.svg`)"/>
    </span>
</template>

<script>
export default {
    name: 'icon-navbar',
    props: ['path'],
}
</script>