import Vue from 'vue'
import VueRouter from 'vue-router'
import { tutorialRoutes } from '@/config/tutorial'

Vue.use(VueRouter)

/*
	Dash
	Devices
	Device Groups
	Rules

	Products
	Categories

	Dispatch
	Incoming Dispatch
	Transaction Dispatch 

	Fields / Paddock List
	Livestock List
	Actions List
	Reports

	Teams
	Settings
*/

const routes = [
    /* IOT dashboard */
    {
        path: '/',
        name: 'Dash',
        component: () =>
            import ('../views/Dash.vue')
    },

    {
        path: '/devices',
        name: 'Devices',
        component: () =>
            import ('../views/Devices.vue')
    },
    {
        path: '/devices/:id',
        name: 'DevicesOverview',
        props: true,
        component: () =>
            import ('../views/DevicesOverview.vue')
    },
    {
        path: '/devices-dynamo/:id',
        name: 'DevicesOverviewDynamo',
        props: true,
        component: () =>
            import ('../views/DevicesOverviewDynamo.vue')
    },
    {
        path: '/device-groups',
        name: 'DevicesGroups',
        component: () =>
            import ('../views/deviceGroups/DeviceGroups')
    },
    {
        path: '/device-groups/add',
        name: 'AddDeviceGroup',
        component: () =>
            import ('../views/deviceGroups/AddDeviceGroup')
    },
    {
        path: '/device-groups/:id',
        name: 'DeviceGroupPage',
        props:true,
        component: () =>
            import ('../views/deviceGroups/DeviceGroupPage')
    },
    {
        path: '/rules',
        name: 'Rules',
        component: () =>
        import ('../views/rules/Rules')
    },
    {
        path: '/rules/add',
        name: 'RulesAdd',
        component: () =>
            import ('../views/rules/RulesAdd')
    },
    {
        path: '/rules/:id',
        name: 'RulesEdit',
        props:true,
        component: () =>
            import ('../views/rules/RulesEdit')
    },
    {
        path: '/map/:name',
        name: 'MarkersMap',
        props:true,
        component: () =>
            import ('../views/MarkersMap.vue'),
    },


    /* Products */
    {
        path: '/products',
        name: 'Products',
        component: () =>
            import ('../views/products/Products.vue'),
    },
    {
        path: '/products/add',
        name: 'SingleProductAdd',
        props: true,
        component: () =>
            import ('../views/products/Add.vue')
    },
    {
        path: '/products/:productID',
        name: 'SingleProduct',
        props: true,
        component: () =>
            import ('../views/products/Single.vue')
    },
    {
        path: '/products/edit/:productID',
        name: 'SingleProductEdit',
        props: true,
        component: () =>
            import ('../views/products/Edit.vue')
    },
    {
        path: '/categories',
        name: 'Categories',
        component: () =>
            import ('../views/products/Categories.vue')
    },
    {
        path: '/categories/add',
        name: 'CategoriesAdd',
        component: () =>
            import ('../views/products/CategoriesAdd.vue')
    },
    {
        path: '/categories/:id',
        name: 'CategoriesView',
        props:true,
        component: () =>
            import ('../views/products/CategoriesView.vue')
    },


    /* Dispatch */
    {
        path: '/dispatch',
        name: 'Dispatch',
        component: () =>
            import ('../views/dispatch/Dispatch.vue')
    },

    {
        path: '/dispatch/:id',
        name: 'DispatchPage',
        props:true,
        component: () =>
            import ('../views/dispatch/DispatchPage.vue')
    },
    {
        path: '/qr-dispatch/:id',
        name: 'QR Dispatch',
        props:true,
        component: () =>
            import ('../views/dispatch/QRDispatch.vue')
    },
    {
        path: '/track-dispatch/:id',
        name: 'Track The Dispatch',
        props:true,
        component: () =>
            import ('../views/dispatch/TrackTheDispatch.vue')
    },
    {
        path: '/dispatch/approval/:id',
        name: 'DisatchPageApproval',
        props:true,
        component: () =>
            import ('../views/dispatch/DispatchPageApproval.vue')
    },
    {
        path: '/dispatch/modify/:id',
        name: 'DispatchModify',
        props:true,

        component: () =>
            import ('../views/dispatch/DispatchModify.vue')
    },
    {
        path: '/dispatch/cancel/:id',
        name: 'DispatchCancel',
        props:true,
        component: () =>
            import ('../views/dispatch/DispatchCancel.vue')
    }, //test route
    {
        path: '/incoming-dispatch',
        name: 'IncomingDispatch',
        component: () =>
            import ('../views/incomingDispatch/IncomingDispatch.vue')
    },
    {
        path: '/transaction-history',
        name: 'TransactionHistory',
        component: () =>
            import ('../views/TransactionHistory.vue')
    },
    {
        path: '/qr-scanner',
        name: 'QR Scanner',
        component: () =>
            import ('../views/QRScanner.vue')
    },


    
    //test route
    // {
    //     path: '/dispatch-modify',
    //     name: 'DispatchModify',
    //     component: () =>
    //         import ('../views/dispatch/DispatchModify.vue')
    // }, //test route
    {
        path: '/create-dispatch',
        name: 'CreateDispatch',
        component: () =>
            import ('../views/dispatch/CreateDispatch.vue')
    }, //test route
   
    {
        path: '/dispatch-page',
        name: 'DispatchPage',
        component: () =>
            import ('../views/dispatch/DispatchPage.vue')
    }, //test route
    {
        path: '/dispatch-cancel',
        name: 'DispatchCancel',
        component: () =>
            import ('../views/dispatch/DispatchCancel.vue')
    }, //test route
    {
        path: '/dispatch-edit-qr',
        name: 'DispatchEditQR',
        component: () =>
            import ('../views/dispatch/DispatchEditQR.vue')
    },

  

    /* Farm management */
    {
        path: '/farm-map',
        name: 'FarmMap',
        component: () =>
            import ('../views/farmMap/FarmMap.vue')
    },
    {
        path: '/add-paddock',
        name: 'AddPaddock',
        component: () =>
            import ('../views/farmMap/AddPaddock.vue')
    },
    {
        path: '/add-field',
        name: 'AddField',
        component: () =>
            import ('../views/farmMap/AddField.vue')
    },
    {
        path: '/add-building',
        name: 'AddBuilding',
        component: () =>
            import ('../views/farmMap/AddBuilding.vue')
    },
    {
        path: '/add-herd',
        name: 'AddHerd',
        component: () =>
            import ('../views/farmMap/AddHerd.vue')
    },
    {
        path: '/fields-list',
        name: 'FieldsPaddockList',
        component: () =>
            import ('../views/FieldsPaddockList.vue')
    },
    {
        path: '/tools-equipments-list',
        name: 'ToolsEquipmentsList',
        component: () =>
            import ('../views/FieldsPaddockList.vue')
    },
    {
        path: '/tools-buildings-list',
        name: 'BuildingsList',
        component: () =>
            import ('../views/FieldsPaddockList.vue')
    },
    {
        path: '/livestock-list',
        name: 'LivestockList',
        component: () =>
            import ('../views/LivestockList.vue')
    },
    // Actions
    {
        path: '/actions-list',
        name: 'ActionsList',
        component: () =>
            import ('../views/actionsList/ActionsList.vue')
    },
    {
        path: '/add-action',
        name: 'AddAction',
        component: () =>
            import ('../views/farmMap/AddAction.vue')
    },
    {
        path: '/action/:id',
        name: 'EditAction',
        component: () =>
            import ('../views/farmMap/AddAction.vue')
    },
    {
        path: '/action/view/:id',
        name: 'ViewAction',
        component: () =>
            import ('../views/farmMap/ViewAction.vue')
    },
    // Reports
    {
        path: '/reports',
        name: 'Reports',
        component: () =>
            import ('../views/reports/Reports.vue')
    },
    {
        path: '/reports/:category',
        name: 'ReportsCategory',
        component: () =>
            import ('../views/reports/ReportLayout.vue')
    },
    {
        path: '/report/:id',
        name: 'Report',
        component: () =>
            import ('../views/reports/ReportsInner.vue')
    },
    {
        path: '/reports/edx-analysis/add',
        name: 'AddEdx',
        props:true,
        component: () =>
            import ('../views/reports/inners/edxanalysis/AddEDX')
    },
    {
        path: '/reports/edx-analysis/edit/:id',
        name: 'AddEdx',
        props:true,
        component: () =>
            import ('../views/reports/inners/edxanalysis/AddEDX')
    },
    {
        path: '/reports/edx-analysis/:id',
        name: 'SingleEDX',
        props:true,
        component: () =>
            import ('../views/reports/inners/edxanalysis/SingleEDX')
    },
    {
        path: '/reports/custom/add',
        name: 'AddEdx',
        props:true,
        component: () =>
            import ('../views/reports/inners/custom/AddCustomReport')
    },
    {
        path: '/reports/custom/edit/:id',
        name: 'EditCustom',
        props:true,
        component: () =>
            import ('../views/reports/inners/custom/AddCustomReport')
    },
    {
        path: '/reports/custom/:id',
        name: 'SingleEDX',
        props:true,
        component: () =>
            import ('../views/reports/inners/custom/SingleCustomReport')
    },


    /* Settings */
    {
        path: '/teams',
        name: 'Teams',
        component: () =>
            import ('../views/Teams/Teams.vue')
    },
    {
        path: '/teams/add',
        name: 'TeamsAdd',
        component: () =>
            import ('../views/Teams/TeamsAdd.vue')
    },
    {
        path: '/teams/edit/:id',
        name: 'TeamsEdit',
        props:true,
        component: () =>
            import ('../views/Teams/TeamsAdd.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () =>
            import ('../views/Settings.vue')
    },

    // qr mobile
    {
        path: '/qr-mobile',
        name: 'QRMobile',
        component: () =>
            import ('../views/QRMobile.vue')
    },
    // {
    //     path: '/product-history',
    //     name: 'ProductHistory',
    //     component: () =>
    //         import ('../views/mobile/ProductHistory.vue')
    // },
    // {
    //     path: '/company',
    //     name: 'Company',
    //     component: () =>
    //         import ('../views/mobile/Company.vue')
    // },

    /* Other */
    {
        path: '/change-account-company',
        name: 'ChangeAccountCompany',
        component: () =>
            import ('../views/ChangeAccountCompany.vue')
    },

    /* Legal */
    {
        path: '/terms',
        name: 'Terms',
        component: () =>
            import ('../views/Terms.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () =>
            import ('../views/Terms.vue')
    },
    {
        path: '/security',
        name: 'Security',
        component: () =>
            import ('../views/Terms.vue')
    },
    {
        path: '/support',
        name: 'Support',
        component: () =>
            import ('../views/support/Support.vue')
    },

    /* DEV PAGES */
    {
        path: '/UI',
        name: 'UI',
        component: () =>
            import ('../views/UI.vue')
    },

    /* UNDER CONSTRUCTION */
    {
        path: '*',
        name: 'UnderConstruction',
        component: () =>
            import ('../views/UnderConstruction.vue')
    },

    // TUTORIAL ROUTES
    ...tutorialRoutes,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router