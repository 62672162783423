<template>
	<div class="pagination">
		<div class="pagination__body">
			<slot></slot>
			<div class="pagination__list-wrapper">
				<a @click="paginationPrev" class="pagination__btn prev" :class="{ disabled: !canPrev }">
					<inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)"/>
					<span>{{ $translate('prev') }}</span>
				</a>
				<ul class="pagination__list">
					<li v-for="(p, index) in pages" :key="index">
						<a @click="setCurrent(index)" :class="{ active: isCurrent(index) }">{{ p }}</a>
					</li>
				</ul>
				<a @click="paginationNext" class="pagination__btn next" :class="{ disabled: !canNext }">
					<span>{{ $translate('next') }}</span>
					<inline-svg :src="require(`@/assets/img/Arrow-next.svg`)"/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import DropdownSimple from '@/components/base/DropdownSimple.vue';
export default {
	name: 'PopupPagination',
	components: {
		DropdownSimple
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		value: {
			type: Array,
			default: () => []
		},
	},

	watch: {
		value: function(val){
			this.paginate();
		},
		items: function(){
			this.paginate();
		}
	},

	data() {
		return {
			perPage: 4,
			current: 0
		}
	},

	computed: {
		pages() {
			let result = Math.ceil(this.items.length / this.perPage)
			return result > 0 ? result : 1
		},
		paginated() {
			const res = [];
			for (let i = 0; i < this.pages; i++) {
				const start = i * this.perPage;
				const end = start + this.perPage;
				const page = this.items.slice(start, end);
				res.push(page);
			}
			return res;
		},
		isCurrent() {
			return i => i === this.current
		},
		canPrev() {
			return this.current > 0
		},
		canNext() {
			return this.current < (this.pages - 1)
		},

	},

	methods: {
		paginationPrev() {
			if(this.canPrev) this.current--
			this.paginate();
		},
		paginationNext() {
			if(this.canNext) this.current++
			this.paginate();
		},
		setCurrent(i) {
			this.current = i;
			this.paginate();
		},
		// onPerPage() {
		// 	this.current = 0;
		// 	// this.$nextTick(this.paginate);
		// },

		paginate() {
			this.$emit('input', this.paginated[this.current]);
		}
	},
	mounted(){
		this.paginate()
		// this.$emit=('input',this.paginated[0])
	},
}
</script>