/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://lzfbdmqtg5eltpbneqwhmvggo4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jrlvmio37nbv5hpmu7cur5hlye",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeapi",
            "endpoint": "https://cyje2u9j06.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://jvqjo6xyve.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:d19e14fa-7acd-4fef-a6ef-97cf7a5be8ce",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_XZX3eytER",
    "aws_user_pools_web_client_id": "76qocm8k5563eoqf2u24360tj3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "enismaronew7acba147d3044ebcaacbc6122c29b05d113014-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
