import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { API, Storage, Auth } from 'aws-amplify';

import {
    getCompany,
    listDevices,
    listDeviceGroups,
    listRules,
    listAlerts,
    listProducts,
    listCategories,
    listDispatches,
    listFarms,
    listPaddocks,
    listFarmActions,
    listReports,
    listCustomReports,
    getDispatch,
    getDevice,
    getProduct,
    listCompanies,
} from '@/graphql/queries';

export default new Vuex.Store({
    state: {
        authState: null,
        authData: null,

        userData: null,
        companyData: null,
        allDevices:[],
        iotDevices:[],
        devices: [],
        products: [],
        receivedProducts:[],
        categories: [],
        dispatches:[],
        activeDispatch:{},
        groups:[],
        rules:[],
        alerts:[],
        profile_photo: null,
        farm: null,
        paddocks: [],
        farmActions: [],
        farmReports: [],
        customReports: [],
        edxReports:[],
        ui: {  
            notificationPopup: {
                show: false,
                message: '',
                status: 'SUCCESS', // ERROR, INFO ?
            }
        },

        iotData: [],
        iotDevicesFromDynamo: [],

        stripe: false,
        lang: null,
    },
    mutations: {
        SET_AUTH(state, { authState, authData }) {
            state.authData = authData;
            state.authState = authState;
        },

        SET_AUTH_ATTR(state, { field, value }) {
            state.authData[field] = value
        },

        SET_USERDATA(state, userData) {
            state.userData = userData
        },

        SET_COMPANYDATA(state, companyData) {
            state.companyData = companyData
        },

        SET_DEVICES(state, devices) {
            state.devices = devices
        },

        SET_IOT_DATA(state, data) {
            state.iotData = data;
            state.iotDevicesFromDynamo = [...new Set(data.map(iot => iot.device_id))]
        },

        SET_ALLDEVICES(state, devices) {
            state.allDevices = devices
        },
        SET_IOTDEVICES(state, devices) {
            state.iotDevices = devices
        },
        SET_RULES(state, rules) {
            state.rules = rules
        },
        SET_ALERTS(state, alerts) {
            state.alerts = alerts
        },

        SET_PRODUCTS(state, products) {
            state.products = products.filter(p=>p.status!=='received')
            // state.receivedProducts = products.filter(p=>p.status==='received')
        },

        SET_RECEIVED_PRODUCTS(state, products) {
            for (let index = 0; index < products.length; index++) {
                if(!state.receivedProducts.map(item => item.id).includes(products[index].id)){
                    state.receivedProducts.push(products[index]);
                }
            }
        },

        SET_CATEGORIES(state, categories) {
            state.categories = categories
        },
        SET_DISPATCHES(state, dispatches) {
            state.dispatches = dispatches
        },

        SET_GROUPS(state,groups){
            state.groups=groups
        },
        SET_FARM(state,farm){
            state.farm = farm;
        },
        SET_PADDOCKS(state,paddocks){
            if(state.farm) state.paddocks = paddocks;
        },
        SET_FARMACTIONS(state, actions){
            state.farmActions = actions;
        },
        SET_FARMREPORTS(state, actions){
            state.farmReports = actions;
        },
        SET_CUSTOMREPORTS(state, actions){
            state.customReports = actions;
        },
        SET_EDXREPORTS(state, actions){
            state.edxReports = actions;
        },
        SET_ACTIVE_DISPATCH(state, actions){
            state.activeDispatch = actions;
        },
        ADD_ITEM(state, { field, item }) {
            state[field].push(item);
        },

        REMOVE_ITEM(state, { field, id }) {
            state[field] = state[field].filter(item => item.id !== id)
        },

        UPDATE_ITEM(state, { field, item }) {
            const targetIndex = state[field].findIndex(target => target.id === item.id);
            if (targetIndex === -1) {
                state[field].push(item)
            } else {
                state[field].splice(targetIndex,1)
                state[field].push(item)
                // state[field][targetIndex] = {
                //     ...state[field][targetIndex],
                //     ...item
                // }
            }
        },

        SET_PROFILEPHOTO(state, profile_photo) {
            state.profile_photo = profile_photo
        },
        SET_UI(state, payload) {
            const { field, value } = payload;
            state.ui[field] = value;
        },

        SET_STRIPE(state, payload) {
            state.stripe = payload;
        },

        SET_LANG(state, lang) {
            state.lang = lang;
        }
    },

    actions: {
        async GET_COMPANYDATA({ commit, state }) {
            let companyData = await API.graphql({
                query: getCompany,
                variables: {
                    id: state.companyData.id,
                },
            }).then((res) => res.data.getCompany);
            commit('SET_COMPANYDATA', companyData);
            return companyData
        },
        async GET_CATEGORIES({ commit, state }) {
            const filter = {
                companyID: {
                    eq: state.companyData.id
                }
            }
            const categories = await API.graphql({
                query: listCategories,
                variables: {
                    filter
                }
            }).then(res => res.data.listCategories.items)

            commit('SET_CATEGORIES', categories);
            return categories
        },

        async GET_DEVICES({ commit, state }) {
            const filter = { companyID: { eq: state.companyData.id } }
            const allDevices = await API.graphql({
                query: listDevices,
                variables: {
                    filter
                }
            }).then(res => res.data.listDevices.items)
            let enabledDevices = []
            let disabledDevices = []

            allDevices.forEach(device => {
                if(device.iot_dashboard_active){
                    enabledDevices.push(device)
                }else{
                    disabledDevices.push(device)
                }
            })

            let device = enabledDevices.filter(device => !device.device_from_iot_data)
            let iotDevice = enabledDevices.filter(device => device.device_from_iot_data)

            // SET_DEVICES(state, devices) {
            //     state.devices = devices
            // },
            // SET_ALLDEVICES(state, devices) {
            //     state.devices = devices
            // },
            // SET_IOTDEVICES(state, devices) {
            //     state.devices = devices
            // },

            commit('SET_DEVICES', device);
            commit('SET_ALLDEVICES', allDevices);
            commit('SET_IOTDEVICES', iotDevice);
            return device
        },
        async GET_IOT_DATA({ commit, state }) { 
            if(state.iotData.length) return state.iotData;
			let path = '/items';
			let myInit = {
				headers: {
					"Content-Type" : "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			let iotData = await API.get('stripeapi', path, myInit);

			if(iotData){
                commit('SET_IOT_DATA', iotData);
				return iotData;
			}
		},
        async GET_GROUPS({ commit, state }) {
            const filter = {
                companyID: {
                    eq: state.companyData.id
                }
            }
            const groups = await API.graphql({
                query: listDeviceGroups,
                variables: {
                    filter
                }
            }).then(res => res.data.listDeviceGroups.items)

            commit('SET_GROUPS', groups);
            return groups
        },
        async GET_RULES({ commit, state }) {
            const filter = {
                companyID: {
                    eq: state.companyData.id
                }
            }
            const rules = await API.graphql({
                query: listRules,
                variables: {
                    filter
                }
            }).then(res => res.data.listRules.items)

            commit('SET_RULES', rules);
            return rules
        },
        async GET_ALERTS({ commit, state }) {
            const alerts = await API.graphql({
                query: listAlerts
            }).then(res => res.data.listAlerts.items)

            console.log('alerts:', alerts);

            commit('SET_ALERTS', alerts);
            return alerts;
        },



        async GET_PRODUCTS({ commit, state }) {
            const filter = { companyProductsId: { eq: state.companyData.id } }
            const products = await API.graphql({
                query: listProducts,
                variables: {
                    filter
                }
            }).then(res => res.data.listProducts.items)

            commit('SET_PRODUCTS', products);
            return products
        },

        async GET_DISPATCHES({ commit, state, dispatch }) {
            let companyData = await dispatch('GET_COMPANYDATA');
            
            const filter = { or: companyData.dispatch.items.map(d => ({ id: { eq: d.dispatchID } })) };
            // console.log('filter dispatches',filter)
            let dispatches=[]
            if(filter.or.length){
                dispatches = await API.graphql({ 
                    query: listDispatches,
                    variables: {
                        filter
                    }
                }).then(res => res.data.listDispatches.items)

                let productsFromDispatches = [];

                if(dispatches.length){
                    const dispatchesCompanies = await API.graphql({
                        query: listCompanies,
                        variables: {}
                    }).then(res => res.data.listCompanies.items);

                    dispatches.forEach(async (dispatchItem) => {
                        // console.log('dispatchItem:', dispatchItem);

                        // console.log('dispatchItem companies:', dispatchesCompanies);

                        dispatchItem.receiver = dispatchesCompanies.find(cItem => cItem.id === dispatchItem.companies.items[0].companyID).company_name;
                        dispatchItem.ownerFullName = dispatchItem.owner.fullname;
                        dispatchItem.createdAtDate = new Date(dispatchItem.createdAt).toLocaleDateString().split('.').join('');

                        for (let index2 = 0; index2 < dispatchItem.batches.length; index2++) {
                            for (let index3 = 0; index3 < dispatchItem.batches[index2].products.length; index3++) {
                                if(!dispatchItem.batches[index2].products[index3]) return;

                                const filterProduct = { id: { eq: dispatchItem.batches[index2].products[index3] } }
                                const products = await API.graphql({
                                    query: listProducts,
                                    variables: {
                                        filter: filterProduct
                                    }
                                }).then(res => res.data.listProducts.items);
                                productsFromDispatches = [...productsFromDispatches, ...products];

                                commit('SET_RECEIVED_PRODUCTS', products);
                            }
                        }
                    })
                }
            }



            commit('SET_DISPATCHES', dispatches);
            return dispatches
        },
        
        async GET_DISPATCH({ commit, state },{id}){
            let dispatchSource= await API.graphql({
                query: getDispatch,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getDispatch);
            return dispatchSource
        },
        async GET_PRODUCT({ commit, state },{id}){
            let product = await API.graphql({
                query: getProduct,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getProduct);
            return product
        },
        async GET_ACTIVE_DISPATCH({ commit, state },{id}){
            let dispatchSource= await API.graphql({
                query: getDispatch,
                variables: {
                    id: id,
                },
            }).then((res) => res.data.getDispatch);
                
            // if(this.$store.state.products.length<1){
            //     await this.$store.dispatch("GET_PRODUCTS")
            // }
            // let products=this.$store.state.products
            // if(this.$store.state.devices<1){
            //     await this.$store.dispatch("GET_DEVICES")
            // }
            let devices = [...state.devices];
            let products = [...state.products];

            let batchesRequest = dispatchSource.batches.map(async b=>{
                let newBatch={...b}

                let productsRequests = newBatch.products.map(async batchProductID=>{
                    return await API.graphql({
                        query: getProduct,
                        variables: {
                            id: batchProductID,
                        },
                    }).then((res) => res.data.getProduct);
                })
                await Promise.all(productsRequests).then(res=>newBatch.products=res)
                return newBatch
            })
            await Promise.all(batchesRequest).then(res=>dispatchSource.batches=res)

            if(dispatchSource.contract_conditions){
                let requestsConditions = dispatchSource.contract_conditions.map(async c => {
                    c.batch = dispatchSource.batches.find(b => b.id === c.select_batch_id);

                    if (c.condition_type === "business"){
                        return c
                    }

                    if(c.condition_type === "smart"){
                        c.devices = await API.graphql({
                            query: getDevice,
                            variables: {
                                id: c.select_device,
                            },
                        }).then((res) => res.data.getDevice);
                        return c
                    }
                })
                await Promise.all(requestsConditions).then(res=>dispatchSource.contract_conditions=res)
            }

            let requestsDocuments = dispatchSource?.documents?.map(async d=>{
                return {
                    id: d.key,
                    key: d.key,
                    title:d.title,
                    url: await Storage.get(d.key),
                    selectedType: d.selectedType
                }
            })
            if(requestsDocuments){
                await Promise.all(requestsDocuments).then(responses => dispatchSource.documents = responses);
            }
            
            let requestsCompanies = dispatchSource.companiesStatus.map(async c => { 
                let company = await API.graphql({
                    query: getCompany,
                    variables: {
                        id: c.id,
                    },
                }).then((res) => res.data.getCompany);
                let companyWithPhoto={
                    ...company,
                    profile_photo_src:company.user?.profile_photo?await Storage.get(company.user.profile_photo):require("@/assets/img/user.png")

                }
                return companyWithPhoto
            });
            await Promise.all(requestsCompanies).then(responses => dispatchSource.companies = responses);
            commit('SET_ACTIVE_DISPATCH', dispatchSource);
            return dispatchSource

        },


        async GET_FARM({ commit, state }) {
            const filter = {
                companyID: {
                    eq: state.companyData.id
                }
            }
            const farm = await API.graphql({
                query: listFarms,
                variables: {
                    filter
                }
            }).then(res => res.data.listFarms.items);

            if(farm.length) {
                // console.log('GET_FARM:', farm[0]);
                commit('SET_FARM', farm[0]);
                return farm[0]
            } else {
                console.log('GET_FARM EMPTY');
                return null;
            }
        },
        async GET_PADDOCKS({ commit, state }) {
            const filter = { farmID: { eq: state.farm ? state.farm.id : () => { 
                    this.GET_FARM(); this.GET_PADDOCKS();  
                } 
            }};
            const paddocks = await API.graphql({
                query: listPaddocks,
                variables: {
                    filter
                }
            }).then(res => res.data.listPaddocks.items)

            if(state.farm) {
                commit('SET_PADDOCKS', paddocks);
            }
            return paddocks
        },
        async GET_FARMACTIONS({ commit, state }) {
            let farmActions = await API.graphql({
                query: listFarmActions,
            }).then(res => res.data.listFarmActions.items);

            commit('SET_FARMACTIONS', farmActions.filter(item => item.company_id ? 
                item.company_id === state.companyData.id : false || item.related_persons ? 
                item.related_persons.includes(`${this.$store.state.authData.attributes['custom:full_name']} (${this.$store.state.authData.attributes['email']})`) : false)
            );

            return farmActions;
        },
        async GET_FARMREPORTS({ commit, state }) {
            const farmReports = await API.graphql({
                query: listReports,
            }).then(res => res.data.listReports.items)

            commit('SET_FARMREPORTS', farmReports);

            return farmReports
        },
        async GET_CUSTOMREPORTS({ commit, state }) {
            const filter = {
                type: {
                    eq: 'custom'
                }
            }
            const customReports = await API.graphql({
                query: listCustomReports,
                variables: {
                    filter
                }
            }).then(res => res.data.listCustomReports.items)

            commit('SET_CUSTOMREPORTS', customReports);

            return customReports
        },
        async GET_EDXREPORTS({ commit, state }) {
            const filter = {
                type: {
                    eq: 'edx'
                }
            }
            const edxReports = await API.graphql({
                query: listCustomReports,
                variables: {
                    filter
                }
            }).then(res => res.data.listCustomReports.items)

            commit('SET_EDXREPORTS', edxReports);

            return edxReports
        },

    },
    getters: {
        lang: (state) => state.lang
    },
    modules: {

    }

})