/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePlan = /* GraphQL */ `
  subscription OnCreatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onCreatePlan(filter: $filter) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlan = /* GraphQL */ `
  subscription OnUpdatePlan($filter: ModelSubscriptionPlanFilterInput) {
    onUpdatePlan(filter: $filter) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlan = /* GraphQL */ `
  subscription OnDeletePlan($filter: ModelSubscriptionPlanFilterInput) {
    onDeletePlan(filter: $filter) {
      id
      stripe_id
      title
      is_custom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGlobalSettings = /* GraphQL */ `
  subscription OnCreateGlobalSettings(
    $filter: ModelSubscriptionGlobalSettingsFilterInput
  ) {
    onCreateGlobalSettings(filter: $filter) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGlobalSettings = /* GraphQL */ `
  subscription OnUpdateGlobalSettings(
    $filter: ModelSubscriptionGlobalSettingsFilterInput
  ) {
    onUpdateGlobalSettings(filter: $filter) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGlobalSettings = /* GraphQL */ `
  subscription OnDeleteGlobalSettings(
    $filter: ModelSubscriptionGlobalSettingsFilterInput
  ) {
    onDeleteGlobalSettings(filter: $filter) {
      id
      stripe_public_key
      stripe_secret_key
      stripe_sandbox_publick_key
      stripe_sandbox_secret_key
      stripe_sandbox
      google_maps_api_key
      position_stack_api_key
      centaur_username
      centaur_password
      xfarm_username
      xfarm_password
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
        __typename
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companies {
            nextToken
            __typename
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
            __typename
          }
          documents {
            key
            title
            selectedType
            __typename
          }
          batches {
            id
            name
            products
            __typename
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
          __typename
        }
        __typename
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
        __typename
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companies {
            nextToken
            __typename
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
            __typename
          }
          documents {
            key
            title
            selectedType
            __typename
          }
          batches {
            id
            name
            products
            __typename
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
          __typename
        }
        __typename
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      fullname
      username
      profile_photo
      role
      grants
      location {
        lat
        lng
        address
        __typename
      }
      email
      period_end
      period_start
      status
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatches {
        items {
          id
          dispatchID
          status
          ownerID
          owner {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companies {
            nextToken
            __typename
          }
          incomingCompanies
          companiesStatus {
            id
            type
            status
            dispatchModifiedID
            pending_date
            approved_date
            delivery_date
            incoming_date
            reject_date
            return_date
            cancel_date
            delivered_date
            receive_date
            __typename
          }
          documents {
            key
            title
            selectedType
            __typename
          }
          batches {
            id
            name
            products
            __typename
          }
          contract_conditions {
            condition_type
            select_batch_id
            select_device
            parameter_obeserved
            operator
            value
            type
            event_to_be_monitored
            custom_event
            from_date
            from_time
            to_date
            to_time
            date
            time
            description_of_certifications_required
            description_of_the_business_rule
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      teamID
      api_pofiles {
        name
        fields {
          username
          password
          __typename
        }
        __typename
      }
      max_team_members
      stripe_customer_id
      team_limit
      subscription_plan_id
      temporary_password
      createdAt
      updatedAt
      userCompanyId
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onCreateCompany(filter: $filter) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
        __typename
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyCertificationsId
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        nextToken
        __typename
      }
      categories {
        items {
          id
          name
          products {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      team
      company_photo {
        key
        company
        __typename
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onUpdateCompany(filter: $filter) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
        __typename
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyCertificationsId
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        nextToken
        __typename
      }
      categories {
        items {
          id
          name
          products {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      team
      company_photo {
        key
        company
        __typename
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onDeleteCompany(filter: $filter) {
      id
      company_name
      year_of_foundation
      history_of_company
      website
      production_per_year
      production_area
      soils
      cellar_work
      productivity
      philosophy
      vat
      fiscal
      country
      city
      province
      lat_lng {
        lat
        lng
        address
        __typename
      }
      location
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyCertificationsId
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        nextToken
        __typename
      }
      categories {
        items {
          id
          name
          products {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      device_groups {
        items {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      dispatch {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      team
      company_photo {
        key
        company
        __typename
      }
      createdAt
      updatedAt
      companyFarmId
      companyUserId
      __typename
    }
  }
`;
export const onCreateMedia = /* GraphQL */ `
  subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onCreateMedia(filter: $filter) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
      __typename
    }
  }
`;
export const onUpdateMedia = /* GraphQL */ `
  subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
    onUpdateMedia(filter: $filter) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
      __typename
    }
  }
`;
export const onDeleteMedia = /* GraphQL */ `
  subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
    onDeleteMedia(filter: $filter) {
      id
      key
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyMediaId
      productMediaId
      __typename
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onCreateDocument(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
      __typename
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onUpdateDocument(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
      __typename
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onDeleteDocument(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyDocumentationId
      productDocumentationId
      __typename
    }
  }
`;
export const onCreateCertificate = /* GraphQL */ `
  subscription OnCreateCertificate(
    $filter: ModelSubscriptionCertificateFilterInput
  ) {
    onCreateCertificate(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyCertificationsId
      __typename
    }
  }
`;
export const onUpdateCertificate = /* GraphQL */ `
  subscription OnUpdateCertificate(
    $filter: ModelSubscriptionCertificateFilterInput
  ) {
    onUpdateCertificate(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyCertificationsId
      __typename
    }
  }
`;
export const onDeleteCertificate = /* GraphQL */ `
  subscription OnDeleteCertificate(
    $filter: ModelSubscriptionCertificateFilterInput
  ) {
    onDeleteCertificate(filter: $filter) {
      id
      key
      title
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      companyCertificationsId
      __typename
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
    onCreateProduct(filter: $filter) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parent_products {
        id
        type
        __typename
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
          __typename
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
        __typename
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
        __typename
      }
      createdAt
      updatedAt
      companyProductsId
      __typename
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
    onUpdateProduct(filter: $filter) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parent_products {
        id
        type
        __typename
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
          __typename
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
        __typename
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
        __typename
      }
      createdAt
      updatedAt
      companyProductsId
      __typename
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
    onDeleteProduct(filter: $filter) {
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      id
      title
      status
      category {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      quantity
      units
      description
      media {
        items {
          id
          key
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyMediaId
          productMediaId
          __typename
        }
        nextToken
        __typename
      }
      documentation {
        items {
          id
          key
          title
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          companyDocumentationId
          productDocumentationId
          __typename
        }
        nextToken
        __typename
      }
      serial_number
      manufacturing_date
      expiry_date
      invoice_number
      purchase_order
      rfid_number
      batch_id
      location {
        lat
        lng
        address
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      parent_products {
        id
        type
        __typename
      }
      farmID
      paddocks
      qr_settings {
        location
        history_of_company
        media
        documentation
        year_of_foundation
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        certifications
        philosophy
        devices {
          device_type
          config
          __typename
        }
        datetime_creation
        rules_business_conditions
        datetime_dispatch
        datetime_receive
        datetime_return
        transaction_history
        device_rule_log
        basic_info
        parent_products
        list_of_dispatches
        linked_IOT_devices
        smart_conditions
        actions_info
        __typename
      }
      dispatches
      receiveData {
        dispatchID
        companyID
        receiveDate
        __typename
      }
      createdAt
      updatedAt
      companyProductsId
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onCreateCategory(filter: $filter) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onUpdateCategory(filter: $filter) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onDeleteCategory(filter: $filter) {
      id
      name
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          category {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onCreateDevice(filter: $filter) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      warranty_period
      location {
        lat
        lng
        address
        __typename
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
        __typename
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          ruleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
            __typename
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
          __typename
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
            __typename
          }
          radius
          __typename
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
          __typename
        }
        land_register
        createdAt
        updatedAt
        __typename
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onUpdateDevice(filter: $filter) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      warranty_period
      location {
        lat
        lng
        address
        __typename
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
        __typename
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          ruleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
            __typename
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
          __typename
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
            __typename
          }
          radius
          __typename
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
          __typename
        }
        land_register
        createdAt
        updatedAt
        __typename
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onDeleteDevice(filter: $filter) {
      id
      name
      category
      description
      date_of_manufacture
      date_of_purchase
      warranty_period
      location {
        lat
        lng
        address
        __typename
      }
      device_info
      status
      iot_dashboard_active
      device_type
      config {
        device_id
        getaway_id
        band
        __typename
      }
      alerts {
        items {
          id
          name
          datetime
          description
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          ruleId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      rules {
        items {
          id
          name
          status
          deviceID
          device {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          behavior {
            observation_period
            parameter
            behavior
            day_of_the_month
            day_of_the_week
            time_of_observation_from
            time_of_observation_to
            operator
            value
            temporal_settings
            telemetry
            aggregation
            __typename
          }
          alert_actions {
            sms_email
            dashboard
            m2m
            webhook
            display_name
            note
            mode
            email
            tel
            deviceID
            device_action
            callback_url
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      productID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      categoryID
      device_category {
        id
        slug
        name
        color
        icon
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      device_groupID
      device_group {
        id
        name
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      paddockID
      device_paddock {
        id
        type
        basic_info {
          name
          total_area
          land_use
          colour_on_map
          description
          type
          breed
          date_of_birth
          withholding_time
          dosage
          dosage_rate
          date_of_the_treatment
          container_unit
          container_size
          container_prize
          expiry_date
          size
          data
          withholding
          weight
          cost
          batch_number
          plots_config {
            crop
            cropOther
            variety
            crop_year
            __typename
          }
          status
          sex
          tag_number
          birth_weight
          raised_or_purchased
          disposal
          disposal_date
          position
          brand
          model
          working_width
          power
          production_year
          plate_number
          frame_number
          date_of_purchase
          revision_date
          date_of_technical_check
          registration_document
          insurance_document
          user_manual
          photo
          custom_type
          __typename
        }
        map_info {
          center_lat
          center_lng
          type
          points {
            lat
            lng
            __typename
          }
          radius
          __typename
        }
        farmID
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        child_paddock
        soil {
          soil_map
          granulometry
          sand
          slit
          clay
          ph
          carbonates
          organic_substance
          organic_carbon
          mineral_nitrogen
          __typename
        }
        land_register
        createdAt
        updatedAt
        __typename
      }
      device_iot_data_id
      device_from_iot_data
      custom_category
      shedule
      sheduleRepeat
      periodOfActivation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeviceCategory = /* GraphQL */ `
  subscription OnCreateDeviceCategory(
    $filter: ModelSubscriptionDeviceCategoryFilterInput
  ) {
    onCreateDeviceCategory(filter: $filter) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeviceCategory = /* GraphQL */ `
  subscription OnUpdateDeviceCategory(
    $filter: ModelSubscriptionDeviceCategoryFilterInput
  ) {
    onUpdateDeviceCategory(filter: $filter) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeviceCategory = /* GraphQL */ `
  subscription OnDeleteDeviceCategory(
    $filter: ModelSubscriptionDeviceCategoryFilterInput
  ) {
    onDeleteDeviceCategory(filter: $filter) {
      id
      slug
      name
      color
      icon
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeviceGroup = /* GraphQL */ `
  subscription OnCreateDeviceGroup(
    $filter: ModelSubscriptionDeviceGroupFilterInput
  ) {
    onCreateDeviceGroup(filter: $filter) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeviceGroup = /* GraphQL */ `
  subscription OnUpdateDeviceGroup(
    $filter: ModelSubscriptionDeviceGroupFilterInput
  ) {
    onUpdateDeviceGroup(filter: $filter) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeviceGroup = /* GraphQL */ `
  subscription OnDeleteDeviceGroup(
    $filter: ModelSubscriptionDeviceGroupFilterInput
  ) {
    onDeleteDeviceGroup(filter: $filter) {
      id
      name
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRules = /* GraphQL */ `
  subscription OnCreateRules($filter: ModelSubscriptionRulesFilterInput) {
    onCreateRules(filter: $filter) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
        __typename
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRules = /* GraphQL */ `
  subscription OnUpdateRules($filter: ModelSubscriptionRulesFilterInput) {
    onUpdateRules(filter: $filter) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
        __typename
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRules = /* GraphQL */ `
  subscription OnDeleteRules($filter: ModelSubscriptionRulesFilterInput) {
    onDeleteRules(filter: $filter) {
      id
      name
      status
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      behavior {
        observation_period
        parameter
        behavior
        day_of_the_month
        day_of_the_week
        time_of_observation_from
        time_of_observation_to
        operator
        value
        temporal_settings
        telemetry
        aggregation
        __typename
      }
      alert_actions {
        sms_email
        dashboard
        m2m
        webhook
        display_name
        note
        mode
        email
        tel
        deviceID
        device_action
        callback_url
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert($filter: ModelSubscriptionAlertFilterInput) {
    onCreateAlert(filter: $filter) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      ruleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert($filter: ModelSubscriptionAlertFilterInput) {
    onUpdateAlert(filter: $filter) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      ruleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert($filter: ModelSubscriptionAlertFilterInput) {
    onDeleteAlert(filter: $filter) {
      id
      name
      datetime
      description
      deviceID
      device {
        id
        name
        category
        description
        date_of_manufacture
        date_of_purchase
        warranty_period
        location {
          lat
          lng
          address
          __typename
        }
        device_info
        status
        iot_dashboard_active
        device_type
        config {
          device_id
          getaway_id
          band
          __typename
        }
        alerts {
          items {
            id
            name
            datetime
            description
            deviceID
            ruleId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userID
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        productID
        product {
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          id
          title
          status
          category {
            nextToken
            __typename
          }
          quantity
          units
          description
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          serial_number
          manufacturing_date
          expiry_date
          invoice_number
          purchase_order
          rfid_number
          batch_id
          location {
            lat
            lng
            address
            __typename
          }
          devices {
            nextToken
            __typename
          }
          parent_products {
            id
            type
            __typename
          }
          farmID
          paddocks
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          dispatches
          receiveData {
            dispatchID
            companyID
            receiveDate
            __typename
          }
          createdAt
          updatedAt
          companyProductsId
          __typename
        }
        categoryID
        device_category {
          id
          slug
          name
          color
          icon
          devices {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        device_groupID
        device_group {
          id
          name
          devices {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        paddockID
        device_paddock {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        device_iot_data_id
        device_from_iot_data
        custom_category
        shedule
        sheduleRepeat
        periodOfActivation
        createdAt
        updatedAt
        __typename
      }
      ruleId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDispatch = /* GraphQL */ `
  subscription OnCreateDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onCreateDispatch(filter: $filter) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
        __typename
      }
      documents {
        key
        title
        selectedType
        __typename
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        __typename
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDispatch = /* GraphQL */ `
  subscription OnUpdateDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onUpdateDispatch(filter: $filter) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
        __typename
      }
      documents {
        key
        title
        selectedType
        __typename
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        __typename
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDispatch = /* GraphQL */ `
  subscription OnDeleteDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onDeleteDispatch(filter: $filter) {
      id
      dispatchID
      status
      ownerID
      owner {
        id
        fullname
        username
        profile_photo
        role
        grants
        location {
          lat
          lng
          address
          __typename
        }
        email
        period_end
        period_start
        status
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatches {
          items {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        teamID
        api_pofiles {
          name
          fields {
            username
            password
            __typename
          }
          __typename
        }
        max_team_members
        stripe_customer_id
        team_limit
        subscription_plan_id
        temporary_password
        createdAt
        updatedAt
        userCompanyId
        __typename
      }
      companies {
        items {
          id
          companyID
          dispatchID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          dispatch {
            id
            dispatchID
            status
            ownerID
            incomingCompanies
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      incomingCompanies
      companiesStatus {
        id
        type
        status
        dispatchModifiedID
        pending_date
        approved_date
        delivery_date
        incoming_date
        reject_date
        return_date
        cancel_date
        delivered_date
        receive_date
        __typename
      }
      documents {
        key
        title
        selectedType
        __typename
      }
      batches {
        id
        name
        products
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        __typename
      }
      contract_conditions {
        condition_type
        select_batch_id
        select_device
        parameter_obeserved
        operator
        value
        type
        event_to_be_monitored
        custom_event
        from_date
        from_time
        to_date
        to_time
        date
        time
        description_of_certifications_required
        description_of_the_business_rule
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFarm = /* GraphQL */ `
  subscription OnCreateFarm($filter: ModelSubscriptionFarmFilterInput) {
    onCreateFarm(filter: $filter) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFarm = /* GraphQL */ `
  subscription OnUpdateFarm($filter: ModelSubscriptionFarmFilterInput) {
    onUpdateFarm(filter: $filter) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFarm = /* GraphQL */ `
  subscription OnDeleteFarm($filter: ModelSubscriptionFarmFilterInput) {
    onDeleteFarm(filter: $filter) {
      id
      paddocks {
        items {
          id
          type
          basic_info {
            name
            total_area
            land_use
            colour_on_map
            description
            type
            breed
            date_of_birth
            withholding_time
            dosage
            dosage_rate
            date_of_the_treatment
            container_unit
            container_size
            container_prize
            expiry_date
            size
            data
            withholding
            weight
            cost
            batch_number
            status
            sex
            tag_number
            birth_weight
            raised_or_purchased
            disposal
            disposal_date
            position
            brand
            model
            working_width
            power
            production_year
            plate_number
            frame_number
            date_of_purchase
            revision_date
            date_of_technical_check
            registration_document
            insurance_document
            user_manual
            photo
            custom_type
            __typename
          }
          map_info {
            center_lat
            center_lng
            type
            radius
            __typename
          }
          farmID
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          devices {
            nextToken
            __typename
          }
          child_paddock
          soil {
            soil_map
            granulometry
            sand
            slit
            clay
            ph
            carbonates
            organic_substance
            organic_carbon
            mineral_nitrogen
            __typename
          }
          land_register
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companyID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePaddock = /* GraphQL */ `
  subscription OnCreatePaddock($filter: ModelSubscriptionPaddockFilterInput) {
    onCreatePaddock(filter: $filter) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
          __typename
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
        __typename
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
          __typename
        }
        radius
        __typename
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
        __typename
      }
      land_register
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePaddock = /* GraphQL */ `
  subscription OnUpdatePaddock($filter: ModelSubscriptionPaddockFilterInput) {
    onUpdatePaddock(filter: $filter) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
          __typename
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
        __typename
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
          __typename
        }
        radius
        __typename
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
        __typename
      }
      land_register
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePaddock = /* GraphQL */ `
  subscription OnDeletePaddock($filter: ModelSubscriptionPaddockFilterInput) {
    onDeletePaddock(filter: $filter) {
      id
      type
      basic_info {
        name
        total_area
        land_use
        colour_on_map
        description
        type
        breed
        date_of_birth
        withholding_time
        dosage
        dosage_rate
        date_of_the_treatment
        container_unit
        container_size
        container_prize
        expiry_date
        size
        data
        withholding
        weight
        cost
        batch_number
        plots_config {
          crop
          cropOther
          variety
          crop_year
          __typename
        }
        status
        sex
        tag_number
        birth_weight
        raised_or_purchased
        disposal
        disposal_date
        position
        brand
        model
        working_width
        power
        production_year
        plate_number
        frame_number
        date_of_purchase
        revision_date
        date_of_technical_check
        registration_document
        insurance_document
        user_manual
        photo
        custom_type
        __typename
      }
      map_info {
        center_lat
        center_lng
        type
        points {
          lat
          lng
          __typename
        }
        radius
        __typename
      }
      farmID
      farm {
        id
        paddocks {
          items {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      devices {
        items {
          id
          name
          category
          description
          date_of_manufacture
          date_of_purchase
          warranty_period
          location {
            lat
            lng
            address
            __typename
          }
          device_info
          status
          iot_dashboard_active
          device_type
          config {
            device_id
            getaway_id
            band
            __typename
          }
          alerts {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          userID
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          productID
          product {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          categoryID
          device_category {
            id
            slug
            name
            color
            icon
            createdAt
            updatedAt
            __typename
          }
          device_groupID
          device_group {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          paddockID
          device_paddock {
            id
            type
            farmID
            child_paddock
            land_register
            createdAt
            updatedAt
            __typename
          }
          device_iot_data_id
          device_from_iot_data
          custom_category
          shedule
          sheduleRepeat
          periodOfActivation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      child_paddock
      soil {
        soil_map
        granulometry
        sand
        slit
        clay
        ph
        carbonates
        organic_substance
        organic_carbon
        mineral_nitrogen
        __typename
      }
      land_register
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFarmAction = /* GraphQL */ `
  subscription OnCreateFarmAction(
    $filter: ModelSubscriptionFarmActionFilterInput
  ) {
    onCreateFarmAction(filter: $filter) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      media
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFarmAction = /* GraphQL */ `
  subscription OnUpdateFarmAction(
    $filter: ModelSubscriptionFarmActionFilterInput
  ) {
    onUpdateFarmAction(filter: $filter) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      media
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFarmAction = /* GraphQL */ `
  subscription OnDeleteFarmAction(
    $filter: ModelSubscriptionFarmActionFilterInput
  ) {
    onDeleteFarmAction(filter: $filter) {
      id
      category
      content_for_100g
      type_of_acivity
      phenological_stadium
      tool_equipment
      name_of_the_operation
      date_of_the_operation
      name_of_the_product
      active_substance
      dose_hectar
      total_quantity
      total_water_distributed
      adversity
      type_of_adversity
      reason_for_the_treatment
      name_of_the_person
      date_of_start_of_the_operation
      date_of_end_of_the_operation
      time_of_start_of_the_operation
      time_of_end_of_the_operation
      hours_worked
      wind_indensity
      weatcher_conditions
      notes_on_animals_and_plants
      notes_on_furrows
      pest_noticed
      irrigation_system_conditions
      irrigation_system_notes
      other_notes
      related
      company_id
      related_persons
      type_of_vaccine_treatment
      withholding_time
      dosage
      dosage_rate
      date_of_the_treatment
      container_unit
      container_size
      container_prize
      expiry_date
      description
      type_of_product
      product_category
      intervention_reason
      visible_on_qrcode
      media
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFarmReports = /* GraphQL */ `
  subscription OnCreateFarmReports(
    $filter: ModelSubscriptionFarmReportsFilterInput
  ) {
    onCreateFarmReports(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFarmReports = /* GraphQL */ `
  subscription OnUpdateFarmReports(
    $filter: ModelSubscriptionFarmReportsFilterInput
  ) {
    onUpdateFarmReports(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFarmReports = /* GraphQL */ `
  subscription OnDeleteFarmReports(
    $filter: ModelSubscriptionFarmReportsFilterInput
  ) {
    onDeleteFarmReports(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      id
      treatment
      datetime
      category
      related
      actions
      companyID
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomReport = /* GraphQL */ `
  subscription OnCreateCustomReport(
    $filter: ModelSubscriptionCustomReportFilterInput
  ) {
    onCreateCustomReport(filter: $filter) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomReport = /* GraphQL */ `
  subscription OnUpdateCustomReport(
    $filter: ModelSubscriptionCustomReportFilterInput
  ) {
    onUpdateCustomReport(filter: $filter) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomReport = /* GraphQL */ `
  subscription OnDeleteCustomReport(
    $filter: ModelSubscriptionCustomReportFilterInput
  ) {
    onDeleteCustomReport(filter: $filter) {
      id
      name
      type
      number
      product
      images
      comments
      tableColumns
      tableData
      related_persons
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSupportTickets = /* GraphQL */ `
  subscription OnCreateSupportTickets(
    $filter: ModelSubscriptionSupportTicketsFilterInput
  ) {
    onCreateSupportTickets(filter: $filter) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSupportTickets = /* GraphQL */ `
  subscription OnUpdateSupportTickets(
    $filter: ModelSubscriptionSupportTicketsFilterInput
  ) {
    onUpdateSupportTickets(filter: $filter) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSupportTickets = /* GraphQL */ `
  subscription OnDeleteSupportTickets(
    $filter: ModelSubscriptionSupportTicketsFilterInput
  ) {
    onDeleteSupportTickets(filter: $filter) {
      id
      date
      status
      category
      subject
      description
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUpcomingCalls = /* GraphQL */ `
  subscription OnCreateUpcomingCalls(
    $filter: ModelSubscriptionUpcomingCallsFilterInput
  ) {
    onCreateUpcomingCalls(filter: $filter) {
      id
      date
      time
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUpcomingCalls = /* GraphQL */ `
  subscription OnUpdateUpcomingCalls(
    $filter: ModelSubscriptionUpcomingCallsFilterInput
  ) {
    onUpdateUpcomingCalls(filter: $filter) {
      id
      date
      time
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUpcomingCalls = /* GraphQL */ `
  subscription OnDeleteUpcomingCalls(
    $filter: ModelSubscriptionUpcomingCallsFilterInput
  ) {
    onDeleteUpcomingCalls(filter: $filter) {
      id
      date
      time
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFaq = /* GraphQL */ `
  subscription OnCreateFaq($filter: ModelSubscriptionFaqFilterInput) {
    onCreateFaq(filter: $filter) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFaq = /* GraphQL */ `
  subscription OnUpdateFaq($filter: ModelSubscriptionFaqFilterInput) {
    onUpdateFaq(filter: $filter) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFaq = /* GraphQL */ `
  subscription OnDeleteFaq($filter: ModelSubscriptionFaqFilterInput) {
    onDeleteFaq(filter: $filter) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompanyDispatch = /* GraphQL */ `
  subscription OnCreateCompanyDispatch(
    $filter: ModelSubscriptionCompanyDispatchFilterInput
  ) {
    onCreateCompanyDispatch(filter: $filter) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
          __typename
        }
        documents {
          key
          title
          selectedType
          __typename
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          __typename
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyDispatch = /* GraphQL */ `
  subscription OnUpdateCompanyDispatch(
    $filter: ModelSubscriptionCompanyDispatchFilterInput
  ) {
    onUpdateCompanyDispatch(filter: $filter) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
          __typename
        }
        documents {
          key
          title
          selectedType
          __typename
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          __typename
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyDispatch = /* GraphQL */ `
  subscription OnDeleteCompanyDispatch(
    $filter: ModelSubscriptionCompanyDispatchFilterInput
  ) {
    onDeleteCompanyDispatch(filter: $filter) {
      id
      companyID
      dispatchID
      company {
        id
        company_name
        year_of_foundation
        history_of_company
        website
        production_per_year
        production_area
        soils
        cellar_work
        productivity
        philosophy
        vat
        fiscal
        country
        city
        province
        lat_lng {
          lat
          lng
          address
          __typename
        }
        location
        user {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyCertificationsId
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            title
            status
            quantity
            units
            description
            serial_number
            manufacturing_date
            expiry_date
            invoice_number
            purchase_order
            rfid_number
            batch_id
            farmID
            paddocks
            dispatches
            createdAt
            updatedAt
            companyProductsId
            __typename
          }
          nextToken
          __typename
        }
        categories {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        device_groups {
          items {
            id
            name
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        rules {
          items {
            id
            name
            status
            deviceID
            userID
            companyID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        dispatch {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        farm {
          id
          paddocks {
            nextToken
            __typename
          }
          companyID
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        team
        company_photo {
          key
          company
          __typename
        }
        createdAt
        updatedAt
        companyFarmId
        companyUserId
        __typename
      }
      dispatch {
        id
        dispatchID
        status
        ownerID
        owner {
          id
          fullname
          username
          profile_photo
          role
          grants
          location {
            lat
            lng
            address
            __typename
          }
          email
          period_end
          period_start
          status
          devices {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatches {
            nextToken
            __typename
          }
          company {
            id
            company_name
            year_of_foundation
            history_of_company
            website
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            philosophy
            vat
            fiscal
            country
            city
            province
            location
            team
            createdAt
            updatedAt
            companyFarmId
            companyUserId
            __typename
          }
          teamID
          api_pofiles {
            name
            __typename
          }
          max_team_members
          stripe_customer_id
          team_limit
          subscription_plan_id
          temporary_password
          createdAt
          updatedAt
          userCompanyId
          __typename
        }
        companies {
          items {
            id
            companyID
            dispatchID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        incomingCompanies
        companiesStatus {
          id
          type
          status
          dispatchModifiedID
          pending_date
          approved_date
          delivery_date
          incoming_date
          reject_date
          return_date
          cancel_date
          delivered_date
          receive_date
          __typename
        }
        documents {
          key
          title
          selectedType
          __typename
        }
        batches {
          id
          name
          products
          qr_settings {
            location
            history_of_company
            media
            documentation
            year_of_foundation
            production_per_year
            production_area
            soils
            cellar_work
            productivity
            certifications
            philosophy
            datetime_creation
            rules_business_conditions
            datetime_dispatch
            datetime_receive
            datetime_return
            transaction_history
            device_rule_log
            basic_info
            parent_products
            list_of_dispatches
            linked_IOT_devices
            smart_conditions
            actions_info
            __typename
          }
          __typename
        }
        contract_conditions {
          condition_type
          select_batch_id
          select_device
          parameter_obeserved
          operator
          value
          type
          event_to_be_monitored
          custom_event
          from_date
          from_time
          to_date
          to_time
          date
          time
          description_of_certifications_required
          description_of_the_business_rule
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProductsCategory = /* GraphQL */ `
  subscription OnCreateProductsCategory(
    $filter: ModelSubscriptionProductsCategoryFilterInput
  ) {
    onCreateProductsCategory(filter: $filter) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProductsCategory = /* GraphQL */ `
  subscription OnUpdateProductsCategory(
    $filter: ModelSubscriptionProductsCategoryFilterInput
  ) {
    onUpdateProductsCategory(filter: $filter) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProductsCategory = /* GraphQL */ `
  subscription OnDeleteProductsCategory(
    $filter: ModelSubscriptionProductsCategoryFilterInput
  ) {
    onDeleteProductsCategory(filter: $filter) {
      id
      productID
      categoryID
      product {
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        id
        title
        status
        category {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        quantity
        units
        description
        media {
          items {
            id
            key
            createdAt
            updatedAt
            companyMediaId
            productMediaId
            __typename
          }
          nextToken
          __typename
        }
        documentation {
          items {
            id
            key
            title
            createdAt
            updatedAt
            companyDocumentationId
            productDocumentationId
            __typename
          }
          nextToken
          __typename
        }
        serial_number
        manufacturing_date
        expiry_date
        invoice_number
        purchase_order
        rfid_number
        batch_id
        location {
          lat
          lng
          address
          __typename
        }
        devices {
          items {
            id
            name
            category
            description
            date_of_manufacture
            date_of_purchase
            warranty_period
            device_info
            status
            iot_dashboard_active
            device_type
            userID
            companyID
            productID
            categoryID
            device_groupID
            paddockID
            device_iot_data_id
            device_from_iot_data
            custom_category
            shedule
            sheduleRepeat
            periodOfActivation
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        parent_products {
          id
          type
          __typename
        }
        farmID
        paddocks
        qr_settings {
          location
          history_of_company
          media
          documentation
          year_of_foundation
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          certifications
          philosophy
          devices {
            device_type
            config
            __typename
          }
          datetime_creation
          rules_business_conditions
          datetime_dispatch
          datetime_receive
          datetime_return
          transaction_history
          device_rule_log
          basic_info
          parent_products
          list_of_dispatches
          linked_IOT_devices
          smart_conditions
          actions_info
          __typename
        }
        dispatches
        receiveData {
          dispatchID
          companyID
          receiveDate
          __typename
        }
        createdAt
        updatedAt
        companyProductsId
        __typename
      }
      category {
        id
        name
        products {
          items {
            id
            productID
            categoryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        companyID
        company {
          id
          company_name
          year_of_foundation
          history_of_company
          website
          production_per_year
          production_area
          soils
          cellar_work
          productivity
          philosophy
          vat
          fiscal
          country
          city
          province
          lat_lng {
            lat
            lng
            address
            __typename
          }
          location
          user {
            id
            fullname
            username
            profile_photo
            role
            grants
            email
            period_end
            period_start
            status
            teamID
            max_team_members
            stripe_customer_id
            team_limit
            subscription_plan_id
            temporary_password
            createdAt
            updatedAt
            userCompanyId
            __typename
          }
          media {
            nextToken
            __typename
          }
          documentation {
            nextToken
            __typename
          }
          certifications {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          categories {
            nextToken
            __typename
          }
          devices {
            nextToken
            __typename
          }
          device_groups {
            nextToken
            __typename
          }
          rules {
            nextToken
            __typename
          }
          dispatch {
            nextToken
            __typename
          }
          farm {
            id
            companyID
            createdAt
            updatedAt
            __typename
          }
          team
          company_photo {
            key
            company
            __typename
          }
          createdAt
          updatedAt
          companyFarmId
          companyUserId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
