import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
    applyPolyfills,
    defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import InlineSvg from 'vue-inline-svg';
import clickOutside from './directives/clickOutside';

import Provider from './provider'

import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

Vue.directive('click-outside', clickOutside);

Vue.component('inline-svg', InlineSvg);
Amplify.configure(aws_exports);

applyPolyfills().then(() => {
    defineCustomElements(window);
});

import translate from './translate';
Vue.prototype.$translate = translate;

Vue.config.productionTip = false
Vue.config.ignoredElements = [/amplify-\w*/];

const provider = new Provider(store, router);
Vue.prototype.$provider = provider

window.provider = provider;

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

Vue.use(VueToast);

Vue.filter('formatDate', function(value) {
    if (value) {
        return new Date(value).toDateString()
    }
})

Vue.filter('formatDateTime', function(value) {
	if (value) {
		let date = new Date(value);
		if(date.toDateString() === 'Invalid Date') return '-';
		return `${date.toDateString()} ${date.toLocaleTimeString()}`
	}
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')