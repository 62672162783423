<template>
	<div class="label-icon" :class="icon.color">
		<img v-if="icon" :src="require(`@/assets/device-icons/${icon.id === 'weather-station-xsense' ? 'weather-station' : icon.id}.svg`)">
	</div>
</template>

<script>
import { devCats } from '@/config'

export default {
	name: 'DeviceIcon',
	props: {
		category: {
			type: String,
			default: 'temperature'
		},
		isDynamoDbDevice: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		icon() {
			return devCats.find(c => c.id === this.category) || devCats[0];
		
		}
	}
}
</script>